import {createSelector} from 'reselect';

export const getIdImages = (state: any) => state.upload.idImage;
export const getApicalLesionsStatus = (state: any) => state.filterImage.apicalLesionsStatus;
export const getBoneLossStatus = (state: any) => state.filterImage.boneLossStatus;
export const getCariesStatus = (state: any) => state.filterImage.cariesStatus;
export const getRestorationsStatus = (state: any) => state.filterImage.restorationsStatus;
export const getPendingImage = (state: any) => state.upload.pending;
export const getPreloaderImage = (state: any) => state.upload.preloader;
export const getImageBase64 = (state: any) => state.upload.imageBase64;
export const getDateOfBirth = (state: any) => state.upload.dateOfBirth;
export const getImageDate = (state: any) => state.upload.imageDate;
export const getPatientName = (state: any) => state.upload.patientName;
export const getStatusFilter = (state: any) => state.upload.statusFilter;
export const getDataImage = (state: any) => state.upload.dataImage;
export const getShowReport = (state: any) => state.upload.showReport;
export const getShowButton = (state: any) => state.upload.showButton;
export const getNewDataTooth = (state: any) => state.upload.newDataTooth;
export const getDataImageFilter = createSelector(
	getDataImage, getNewDataTooth,
	(data, newData) => {
		const allTooth: any[] = [];
		Object.keys(data).forEach(item => {
			if (typeof data[item] !== 'string') {
				if (data[item].length > 0) {
					data[item].forEach((tooth: any) => {
						const {toothName} = tooth;
						// allTooth.indexOf(toothName);
						if (allTooth.indexOf(toothName) === -1) {
							allTooth.push(toothName);
						}
					});
				}
			}
		});
		if (newData.length > 0) {
			newData.forEach((item: any) => {
				if (allTooth.indexOf(Number(item.toothName)) === -1) {
					allTooth.push(Number(item.toothName));
				}
			});
		}

		return allTooth;
	},
);
export const getDataApical = createSelector(getDataImage,
		data => {
			let apical: any[] = [];
			Object.keys(data).forEach(item => {
				if (item === 'apical') {
					apical = data[item];
				}
			});

			return apical;
		},
);

export const getDataBone = createSelector(getDataImage,
	data => {
		let bone: any[] = [];
		Object.keys(data).forEach(item => {
			if (item === 'bone') {
				bone = data[item];
			}
		});

		return bone;
	},
);
export const getDataCaries = createSelector(getDataImage,
	data => {
		let caries: any[] = [];
		Object.keys(data).forEach(item => {
			if (item === 'caries') {
				caries = data[item];
			}
		});

		return caries;
	},
);
export const getDataRestorations = createSelector(getDataImage,
	data => {
		let restorations: any[] = [];
		Object.keys(data).forEach(item => {
			if (item === 'restorations') {
				restorations = data[item];
			}
		});

		return restorations;
	},
);
export const getDataFilter = createSelector(
	getDataApical,
	getDataBone,
	getDataCaries,
	getDataRestorations,
	getNewDataTooth,
	getApicalLesionsStatus,
	getBoneLossStatus,
	getCariesStatus,
	getRestorationsStatus,
	(apicalLesions, boneLoss, caries, restorations, newData,
	 showApical, showBone, showCaries, showRestorations) => {
		const filterData: object = {};
		if (showApical) {
			if (apicalLesions.length > 0) {
				apicalLesions.forEach(item => {
					filterData[item.toothName] = {apical: true};
				});
			}
			if (newData.length > 0) {
				newData.forEach((item: any) => {
					if (item.apical || item.apical === false) {
						if (filterData[item.toothName]) {
							filterData[item.toothName]['apical'] = item.apical;
						} else {
							filterData[item.toothName] = {apical: item.apical};
						}
					}
				});
			}
		}
		if (showCaries) {
			if (caries.length > 0) {
				caries.forEach(item => {
					if (filterData[item.toothName]) {
						filterData[item.toothName]['caries'] = true;
					} else {
						filterData[item.toothName] = {caries: true};
					}
				});
			}
			if (newData.length > 0) {
				newData.forEach((item: any) => {
					if (item.caries || item.caries === false) {
						if (filterData[item.toothName]) {
							filterData[item.toothName]['caries'] = item.caries;
						} else {
							filterData[item.toothName] = {caries: item.caries};
						}
					}
				});
			}
		}
		if (showBone) {
			if (boneLoss.length > 0) {
				boneLoss.forEach(item => {
					if (filterData[item.toothName]) {
						filterData[item.toothName]['bone'] = true;
					} else {
						filterData[item.toothName] = {bone: true};
					}
				});
			}
			if (newData.length > 0) {
				newData.forEach((item: any) => {
					if (item.bone || item.bone === false) {
						if (filterData[item.toothName]) {
							filterData[item.toothName]['bone'] = item.bone;
						} else {
							filterData[item.toothName] = {bone: item.bone};
						}
					}
				});
			}
		}
		if (showRestorations) {
			if (restorations.length > 0) {
				restorations.forEach(item => {
					const subtype = item.subtype;
					if (filterData[item.toothName]) {
						filterData[item.toothName][subtype] = true;
					} else {
						filterData[item.toothName] = {subtype: true};
						filterData[item.toothName][subtype] = true;
						delete filterData[item.toothName]['subtype'];
					}
				});
			}
			if (newData.length > 0) {
				newData.forEach((item: any) => {
					if (
						item.fillings || item.fillings === false ||
						item.bridges || item.bridges === false ||
						item.implants || item.implants === false ||
						item.crowns || item.crowns === false ||
						item.filling || item.filling === false
					) {
						if (filterData[item.toothName]) {
							filterData[item.toothName]['fillings'] = item.fillings;
							filterData[item.toothName]['bridges'] = item.bridges;
							filterData[item.toothName]['crowns'] = item.crowns;
							filterData[item.toothName]['filling'] = item.filling;
							filterData[item.toothName]['implants'] = item.implants;
						} else {
							filterData[item.toothName] = {
								fillings: item.fillings,
								bridges: item.bridges,
								crowns: item.crowns,
								filling: item.filling,
								implants: item.implants,
							};
						}
					}
				});
			}
		}
		if (newData.length > 0 && (showApical || showCaries || showBone || showRestorations)) {
			newData.forEach((item: any) => {
				if (item.comment || item.comment === '') {
					if (filterData[item.toothName]) {
						filterData[item.toothName]['comment'] = item.comment;
					}
				} else {
					if (
						!item.fillings && !item.bridges && !item.filling
						&& !item.implants && !item.crowns && !item.bone
						&& !item.caries && !item.apical
					) {
						delete filterData[item.toothName];
					}
				}
			});
		}

		return filterData;
	},
);

import React from 'react';
import Modal from 'react-modal';
import {Trans} from 'react-i18next';

import modalOpen from 'resources/icons/modal-down.svg';
import closeIcon from 'resources/icons/addNewImgIcon.svg';

import style from './modalAddFrame.module.scss';

const allTooth = [
	11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28,
	41, 42, 43, 44, 45, 46, 47, 48, 31, 32, 33, 34, 35, 36, 37, 38,
];
const customStyles = {
	content : {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '0',
		backgroundColor: 'transparent',
		border: 'none',
	},
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
	},
};

interface IModalAddFrame {
	modalIsOpenAddButton: boolean;
	closeModalAddButton: () => void;
	submitModalAddButton: (data: any[]) => void;
}
interface IModalAddFrameState {
	selectTooth: any;
	apical: boolean;
	bone: boolean;
	caries: boolean;
	fillings: boolean;
	bridges: boolean;
	crowns: boolean;
	implants: boolean;
	filling: boolean;
	errorSelectTooth: boolean;
	errorSelectToothEmpty: boolean;
	errorSelectToothEmptyNumber: boolean;
	showDetections: boolean;
	emptyDetections: number;
}
export default class ModalAddFrame extends React.Component<IModalAddFrame, IModalAddFrameState> {
	public state: IModalAddFrameState = {
		selectTooth: '',
		errorSelectTooth: false,
		errorSelectToothEmpty: false,
		errorSelectToothEmptyNumber: false,
		apical: false,
		caries: false,
		bone: false,
		fillings: false,
		crowns: false,
		bridges: false,
		implants: false,
		filling: false,
		showDetections: false,
		emptyDetections: 0,
	};
	private readonly handleCloseModal = () => {
		this.setState({
			selectTooth: '',
			errorSelectTooth: false,
			errorSelectToothEmpty: false,
			errorSelectToothEmptyNumber: false,
			apical: false,
			caries: false,
			bone: false,
			fillings: false,
			crowns: false,
			bridges: false,
			implants: false,
			filling: false,
			showDetections: false,
			emptyDetections: 0,
		});
		this.props.closeModalAddButton();
	}

	private readonly handleSubmit = () => {
		const {selectTooth, apical, caries, bone, fillings, crowns, bridges, implants, filling} = this.state;
		const data = [{}];
		if (selectTooth === '') {
			return this.setState({
				errorSelectTooth: false,
				errorSelectToothEmpty: false,
				errorSelectToothEmptyNumber: true,
			});
		}
		if (!isNaN(Number(selectTooth))) {
			if (allTooth.indexOf(Number(selectTooth)) === -1) {
				this.setState({
					errorSelectTooth: false,
					errorSelectToothEmpty: true,
					errorSelectToothEmptyNumber: false,
				});
			} else {
				data[0]['toothName'] = selectTooth;
				if (apical) data[0]['apical'] = apical;
				if (caries) data[0]['caries'] = caries;
				if (bone) data[0]['bone'] = bone;
				if (fillings) data[0]['fillings'] = fillings;
				if (bridges) data[0]['bridges'] = bridges;
				if (implants) data[0]['implants'] = implants;
				if (crowns) data[0]['crowns'] = crowns;
				if (filling) data[0]['filling'] = filling;
				this.props.submitModalAddButton(data);
				this.setState({
					selectTooth: '',
					errorSelectTooth: false,
					errorSelectToothEmpty: false,
					errorSelectToothEmptyNumber: false,
					apical: false,
					caries: false,
					bone: false,
					fillings: false,
					crowns: false,
					bridges: false,
					implants: false,
					filling: false,
					showDetections: false,
					emptyDetections: 0,
				});
			}
		} else {
			this.setState({
				errorSelectTooth: true,
				errorSelectToothEmpty: false,
				errorSelectToothEmptyNumber: false,
			});
		}
	}

	private readonly handleClickApical = () => {
		const {apical, emptyDetections} = this.state;
		this.setState({
			apical: !apical,
			emptyDetections: !apical ? emptyDetections + 1 : emptyDetections - 1,
		});
	}

	private readonly handleClickBone = () => {
		const {bone, emptyDetections} = this.state;
		this.setState({
			bone: !bone,
			emptyDetections: !bone ? emptyDetections + 1 : emptyDetections - 1,
		});
	}

	private readonly handleClickCaries = () => {
		const {caries, emptyDetections} = this.state;
		this.setState({
			caries: !caries,
			emptyDetections: !caries ? emptyDetections + 1 : emptyDetections - 1,
		});
	}

	private readonly handleClickFillings = () => {
		const {fillings, emptyDetections} = this.state;
		this.setState({
			fillings: !fillings,
			emptyDetections: !fillings ? emptyDetections + 1 : emptyDetections - 1,
		});
	}

	private readonly handleClickCrowns = () => {
		const {crowns, emptyDetections} = this.state;
		this.setState({
			crowns: !crowns,
			emptyDetections: !crowns ? emptyDetections + 1 : emptyDetections - 1,
		});
	}

	private readonly handleClickBridges = () => {
		const {bridges, emptyDetections} = this.state;
		this.setState({
			bridges: !bridges,
			emptyDetections: !bridges ? emptyDetections + 1 : emptyDetections - 1,
		});
	}

	private readonly handleClickImplants = () => {
		const {implants, emptyDetections} = this.state;
		this.setState({
			implants: !implants,
			emptyDetections: !implants ? emptyDetections + 1 : emptyDetections - 1,
		});
	}

	private readonly handleClickFilling = () => {
		const {filling, emptyDetections} = this.state;
		this.setState({
			filling: !filling,
			emptyDetections: !filling ? emptyDetections + 1 : emptyDetections - 1,
		});
	}

	private readonly handleChangeInput = (e: any) => {
		if (e.target.value === '') {
			return this.setState({
				selectTooth: e.target.value,
				errorSelectTooth: false,
				errorSelectToothEmpty: false,
				errorSelectToothEmptyNumber: true,
			});
		}
		if (!isNaN(Number(e.target.value))) {
			if (allTooth.indexOf(Number(e.target.value)) === -1) {
				this.setState({
					selectTooth: e.target.value,
					errorSelectTooth: false,
					errorSelectToothEmpty: true,
					errorSelectToothEmptyNumber: false,
				});
			} else {
				this.setState({
					selectTooth: e.target.value,
					errorSelectTooth: false,
					errorSelectToothEmpty: false,
					errorSelectToothEmptyNumber: false,
				});
			}
		} else {
			this.setState({
				selectTooth: e.target.value,
				errorSelectTooth: true,
				errorSelectToothEmpty: false,
				errorSelectToothEmptyNumber: false,
			});
		}
	}

	public render() {
		const {modalIsOpenAddButton} = this.props;
		const {
			selectTooth, apical, bone, caries, fillings,
			crowns, bridges, implants, filling, errorSelectTooth,
			errorSelectToothEmpty, errorSelectToothEmptyNumber,
			emptyDetections,
			showDetections,
		} = this.state;
		let styleAnimate = {transition: 'all 300ms ease-in-out', opacity: 1, display: 'flex'};
		if (!showDetections) {
			styleAnimate = {transition: 'all 300ms ease-in-out', opacity: 0, display: 'none'};
		}

		return (
			<div className={style.modalAddFrame}>
				<Modal
					isOpen={modalIsOpenAddButton}
					style={customStyles}
					ariaHideApp={false}
				>
					<div className={style.modalBlock}>
						<div className={style.numberBlock}>
							<span className={style.numberBlock_title}>
								<Trans i18nKey='app.rightBar.modalAdd.title_number'/>
							</span>
							{errorSelectTooth &&
							<span  className={style.numberBlock_title_error}>
								<Trans i18nKey='app.rightBar.modalAdd.title_number_error'/>
							</span>
							}
							{errorSelectToothEmpty &&
							<span className={style.numberBlock_title_error}>
								<Trans i18nKey='app.rightBar.modalAdd.title_number_error_empty'/>
							</span>
							}
							{errorSelectToothEmptyNumber &&
							<span className={style.numberBlock_title_error}>
								<Trans i18nKey='app.rightBar.modalAdd.title_number_error_number'/>
							</span>
							}
							<input
								value={selectTooth}
								onChange={this.handleChangeInput}
								className={`
								${style.numberBlock_input}
								 ${errorSelectTooth && style.error}
								 ${errorSelectToothEmpty && style.errorEmpty}
								 ${errorSelectToothEmptyNumber && style.errorNumber}
								 `}
								type='text'
							/>
						</div>
						<div className={style.detectionsAllBlock}>
							<div className={style.detectionsBlock}>
							<span className={style.detectionsBlock_title}>
								<Trans i18nKey='app.rightBar.modalAdd.title_detections'/>
							</span>
								<div className={`${style.detectionsBlock_content} ${showDetections && style.activeContent}`}>
									{showDetections ?
										<div className={style.detectionsBlock_content_blockButtons}>
											{apical &&
												<div className={style.detectionsBlock_content_button}>
													Apical Lesions
													<img
														src={closeIcon}
														onClick={this.handleClickApical}
														alt=''
													/>
												</div>
											}
											{bone &&
												<div className={style.detectionsBlock_content_button}>
													Periodontal Bone Loss
													<img
														src={closeIcon}
														onClick={this.handleClickBone}
														alt=''
													/>
												</div>
											}
											{caries &&
												<div className={style.detectionsBlock_content_button}>
													Caries
													<img
														src={closeIcon}
														onClick={this.handleClickCaries}
														alt=''
													/>
												</div>
											}
											{fillings &&
												<div className={style.detectionsBlock_content_button}>
													Fillings
													<img
														src={closeIcon}
														onClick={this.handleClickFillings}
														alt=''
													/>
												</div>
											}
											{crowns &&
												<div className={style.detectionsBlock_content_button}>
													Crown
													<img
														src={closeIcon}
														onClick={this.handleClickCrowns}
														alt=''
													/>
												</div>
											}
											{bridges &&
												<div className={style.detectionsBlock_content_button}>
													Bridge
													<img
														src={closeIcon}
														onClick={this.handleClickBridges}
														alt=''
													/>
												</div>
											}
											{implants &&
											<div className={style.detectionsBlock_content_button}>
												Implants
												<img
													src={closeIcon}
													onClick={this.handleClickImplants}
													alt=''
												/>
											</div>
											}
											{filling &&
												<div className={style.detectionsBlock_content_button}>
													Root Canal Filling
													<img
														src={closeIcon}
														onClick={this.handleClickFilling}
														alt=''
													/>
												</div>
											}
											{emptyDetections === 0 &&
											<div
												onClick={() => this.setState({showDetections: !showDetections})}
												className={style.detectionsBlock_content_close}
											>
												<Trans i18nKey='app.rightBar.modalAdd.title_input'/>
												<img src={modalOpen} alt=''/>
											</div>
											}
										</div> :
										<div
											onClick={() => this.setState({showDetections: !showDetections})}
											className={style.detectionsBlock_content_close}
										>
											<Trans i18nKey='app.rightBar.modalAdd.title_input'/>
											<img src={modalOpen} alt=''/>
										</div>
									}
								</div>
							</div>
							<div className={style.selectBlock} style={styleAnimate}>
								<span className={`${apical && style.active}`} onClick={this.handleClickApical}>
									Apical Lesions
								</span>
								<span className={`${bone && style.active}`} onClick={this.handleClickBone}>
									Periodontal Bone Loss
								</span>
								<span className={`${caries && style.active}`} onClick={this.handleClickCaries}>
									Caries
								</span>
								<span className={`${fillings && style.active}`} onClick={this.handleClickFillings}>
									Fillings
								</span>
								<span className={`${crowns && style.active}`} onClick={this.handleClickCrowns}>
									Crown
								</span>
								<span className={`${bridges && style.active}`} onClick={this.handleClickBridges}>
									Bridge
								</span>
								<span className={`${implants && style.active}`} onClick={this.handleClickImplants}>
									Implant
								</span>
								<span className={`${filling && style.active}`} onClick={this.handleClickFilling}>
									Root Canal Filling
								</span>
							</div>
						</div>
						<div className={style.buttonBlock}>
							<span className={style.buttonBlock_close} onClick={this.handleCloseModal}>
								<Trans i18nKey='app.rightBar.modalAdd.button_close'/>
							</span>
							<span className={style.buttonBlock_submit} onClick={this.handleSubmit}>
								<Trans i18nKey='app.rightBar.modalAdd.button_add'/>
							</span>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

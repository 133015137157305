import {createStandardAction} from 'typesafe-actions';

import {UserTypes} from '../types/userTypes';

export const logOutAction = createStandardAction(UserTypes.LOGOUT_ACTION)();
export const loginAction = createStandardAction(UserTypes.LOGIN_ACTION)();
export const loginActionSuccess =
		createStandardAction(UserTypes.LOGIN_ACTION_SUCCESS)<any>();
export const loginActionError =
	createStandardAction(UserTypes.LOGIN_ACTION_ERROR)();

export const loginActionWithData =
		createStandardAction(UserTypes.LOGIN_ACTION_WITH_DATA)<any>();

import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import testReducer from 'library/common/reducers/testReducer';
import userReducer from 'library/common/reducers/userReducer';
import uploadReducer from 'library/common/reducers/uploadReducer';
import filterImageReducer from 'library/common/reducers/filterImageReducer';

const createCoreReducer = (history: any) => combineReducers({
	router: connectRouter(history),
	test: testReducer,
	user: userReducer,
	upload: uploadReducer,
	filterImage: filterImageReducer,
});

export default createCoreReducer;

import {connect} from 'react-redux';

import {RootState} from 'core/store/configureStore';

import {
	getIdImages,
	getPendingImage,
	getDateOfBirth,
	getImageDate,
	getPatientName,
	getShowReport, getDataFilter,
	getDataImageFilter, getImageBase64,
	getDataApical, getDataBone,
	getDataCaries, getDataRestorations,
} from 'library/common/selectors/upload';
import {uploadActionNewImage, uploadActionShowReport} from 'library/common/actions/upload';
import {filterActionNewImage} from 'library/common/actions/filterImage';

import App from './App';

const mapStateToProps = (store: RootState) => ({
	idImage: getIdImages(store),
	dataFilter: getDataFilter(store),
	apicalLesions : getDataApical(store),
	boneLoss : getDataBone(store),
	caries : getDataCaries(store),
	restorations : getDataRestorations(store),
	imageBase64: getImageBase64(store),
	dataImageFilter: getDataImageFilter(store),
	pendingImage: getPendingImage(store),
	dateOfBirth: getDateOfBirth(store),
	imageDate: getImageDate(store),
	patientName: getPatientName(store),
	showReport: getShowReport(store),
});

export default connect(mapStateToProps, {
	uploadActionNewImage,
	filterActionNewImage,
	uploadActionShowReport,
})(App);

import React from 'react';
import {Trans} from 'react-i18next';
import Dropzone from 'react-dropzone';
import {Redirect} from 'react-router-dom';

import uploadIcon from 'resources/icons/upload.svg';

import styles from './upload.module.scss';

interface IUpload {
	pending: boolean;
	preloader: boolean;
	uploadActionWithData: (data: object) => void;
}

interface IUploadState {
	kind: string;
	patientName: string;
	dateOfBirth: string;
	imageDate: string;
	data: string;
}

export function getDateNow() {
	const d = new Date();
	const curr_date = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
	const curr_month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
	const curr_year = d.getFullYear();

	return `${curr_date}.${curr_month}.${curr_year}`;
}

export default class Upload extends React.Component<IUpload, IUploadState> {

	public state: IUploadState = {
		kind: 'OPG',
		patientName: 'John Doe',
		dateOfBirth: '23.01.1992',
		imageDate: getDateNow(),
		data: '',
	};

	private readonly onDrop = (files: any): void => {
		const file = files[0];
		const reader = new FileReader();
		reader.onload = (event: any) => {
			const {kind, patientName, dateOfBirth, imageDate} = this.state;
			const data = {
				meta: {
					kind,
					patientName,
					dateOfBirth,
					imageDate,
				},
				data: event.target.result,
			};
			this.props.uploadActionWithData(data);
		};
		reader.readAsDataURL(file);
	}

	public render() {
		const {kind} = this.state;
		if (this.props.pending) {
			return <Redirect to='/dashboard' />;
		}

		return (
			<div className={styles.upload}>
				<div className={styles.title}><Trans i18nKey='upload.title_analysis' /></div>
				<div className={styles.analysis}>
					<button
						onClick={() => this.setState({kind: 'OPG'})}
						className={`${styles.org}
						 ${kind === 'OPG' ? styles.active : ''}`}
					>
						<Trans i18nKey='upload.button_panoramic' />
					</button>
					<button
						onClick={() => this.setState({kind: 'BW'})}
						className={`${styles.bw}
						 ${kind === 'BW' ? styles.active : ''}`}
					>
						<Trans i18nKey='upload.button_bitewings' />
					</button>
				</div>
				<div className={styles.title}><Trans i18nKey='upload.title_upload' /></div>
				<Dropzone
					onDrop={this.onDrop}
					noClick={true}
					accept='image/jpeg, image/tiff, image/png, application/pdf, application/dicom'
				>
					{({getRootProps, getInputProps, isDragReject, isDragActive, open}) => (
						<div
							className={
								`${styles.uploadBlock}
								 ${isDragReject && styles.upload_error}
								 ${this.props.preloader && styles.upload_drag}
								  ${isDragActive && !isDragReject && styles.upload_drag}
								  `}
							{...getRootProps()}
						>
							{isDragReject && <span className={styles.error_text}><Trans i18nKey='upload.error_text' /></span>}
							<div><img src={uploadIcon} alt=''/></div>
							{this.props.preloader &&
							<div className={styles.lds_spinner}>
								<div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/>
							</div>
							}
							<div className={styles.subTitle}><Trans i18nKey='upload.title_drag' /></div>
							<div className={`${styles.subTitle} ${styles.subTitle__modif}`}><Trans i18nKey='upload.sub_title_drag' /></div>
							<button onClick={() => open()} disabled={this.props.preloader} className={styles.upload_button}>
								<Trans i18nKey='upload.button_drag' />
								<input {...getInputProps()} />
							</button>
							<div className={styles.format_image}><Trans i18nKey='upload.format_drag' /></div>
						</div>
					)}
				</Dropzone>
			</div>
		);
	}
}

export enum UploadTypes {
	UPLOAD_ACTION_NEW_IMAGE = '@@UPLOAD/UPLOAD_ACTION_NEW_IMAGE',
	UPLOAD_ACTION_PRELOADER_IMAGE = '@@UPLOAD/UPLOAD_ACTION_PRELOADER_IMAGE',
	UPLOAD_ACTION_ERROR = '@@UPLOAD/UPLOAD_ACTION_ERROR',
	UPLOAD_ACTION_DATA_IMAGE = '@@UPLOAD/UPLOAD_ACTION_DATA_IMAGE',
	UPLOAD_ACTION_DATA_IMAGE_SUCCESS = '@@UPLOAD/UPLOAD_ACTION_DATA_IMAGE_SUCCESS',
	UPLOAD_ACTION_WITH_DATA = '@@UPLOAD/UPLOAD_ACTION_WITH_DATA',
	UPLOAD_ACTION_ADD_NEW_TOOTH = '@@UPLOAD/UPLOAD_ACTION_ADD_NEW_TOOTH',
	UPLOAD_ACTION_SUCCESS = '@@UPLOAD/UPLOAD_ACTION_SUCCESS',
	UPLOAD_ACTION_SHOW_REPORT = '@@UPLOAD/UPLOAD_ACTION_SHOW_REPORT',
	UPLOAD_ACTION_WS_OPEN = '@@UPLOAD/UPLOAD_ACTION_WS_OPEN',
	UPLOAD_ACTION_WS_SUCCESS = '@@UPLOAD/UPLOAD_ACTION_WS_SUCCESS',
}

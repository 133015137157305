import {ActionType, getType} from 'typesafe-actions';

import * as actions from '../actions/upload';

export type UploadState = Readonly<{
	idImage: string;
	showReport: boolean;
	showButton: boolean;
	imageBase64: string;
	pending: boolean;
	dateOfBirth: string;
	imageDate: string;
	patientName: string;
	preloader: boolean;
	dataImage: object;
	statusFilter: boolean;
	newDataTooth: any[];
}>;

const initialState: UploadState = {
	idImage: '',
	pending: false,
	showReport: false,
	showButton: true,
	preloader: false,
	imageBase64: '',
	dateOfBirth: '',
	imageDate: '',
	patientName: '',
	dataImage: {},
	newDataTooth: [],
	statusFilter: true,
};

export type UploadActions = ActionType<typeof actions>;

export default (state = initialState, action: UploadActions): UploadState => {
	switch (action.type) {
		case getType(actions.uploadActionNewImage):
			return {
				...initialState,
			};

		case getType(actions.uploadActionSuccess):
			const {imageInfo, data} = action.payload;
			const {dateOfBirth, imageDate, patientName} = imageInfo.meta;

			return {
				...state,
				imageBase64: imageInfo.data,
				dateOfBirth,
				imageDate,
				patientName,
				idImage: data.id,
				pending: true,
			};
		case getType(actions.uploadActionWithData):
			return {
				...state,
			};
		case getType(actions.uploadActionPreloaderImage):
			return {
				...state,
				preloader: true,
			};
		case getType(actions.uploadActionError):
			return {
				...state,
				preloader: false,
			};
		case getType(actions.uploadActionShowReport):
			return {
				...state,
				showReport: !state.showReport,
			};
		case getType(actions.uploadActionGetDataImage):

			return {
				...state,
			};
		case getType(actions.uploadActionGetDataImageSuccess):

			return {
				...state,
				showButton: false,
				dataImage: action.payload,
			};
		case getType(actions.uploadActionAddNewTooth):
			const newData = [...state.newDataTooth, action.payload[0]];

			return {
				...state,
				newDataTooth: newData,
			};
		case getType(actions.uploadActionOpenWsSuccess):

			return {
				...state,
				statusFilter: false,
			};
		default:
			return state;
	}
};

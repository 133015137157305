import React from 'react';
import {Redirect} from 'react-router-dom';
import {Trans} from 'react-i18next';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import HeaderFrame from './Frames/HeaderFrame/';
import SubHeaderFrame from './Frames/SubHeaderFrame/';
import ViewerFrame from './Frames/ViewerFrame/';
import RightBarFrame from './Frames/RightBarFrame/';
import SubHeaderReportFrame from './Frames/SubHeaderReportFrame/';
import ViewerReportFrame from './Frames/ViewerReportFrame';

import reportIcon from 'resources/icons/reportIconPdf.svg';
import reportNewIcon from 'resources/icons/reportNewImg.svg';
import reportLeft from 'resources/icons/reportLeft.svg';

import styles from './app.module.scss';

interface IApp {
	pendingImage: boolean;
	showReport: boolean;
	dateOfBirth: string;
	imageDate: string;
	patientName: string;
	idImage: string;
	dataImageFilter: any[];
	dataFilter: object;
	uploadActionNewImage: () => void;
	filterActionNewImage: () => void;
	uploadActionShowReport: () => void;
	apicalLesions: any[];
	boneLoss: any[];
	caries: any[];
	restorations: any[];
	imageBase64: string;
}

export default class App extends React.Component<IApp> {
	private myRef: any;
	private myRefNext: any;
	private readonly exportPdf = async () => {
		const pdf = new JsPDF('p', 'mm', 'a4');
		const width = pdf.internal.pageSize.getWidth();
		let height = pdf.internal.pageSize.getHeight();
		const divHeight = this.myRef.current.clientHeight;
		const divWidth = this.myRef.current.clientWidth;
		const divHeightNext = this.myRefNext.current.clientHeight;
		const divWidthNext = this.myRefNext.current.clientWidth;
		const ratio = divHeight / divWidth;
		const ratio2 = divHeightNext / divWidthNext;
		await html2canvas(this.myRef.current, {height: divHeight, width: divWidth}) // tslint:disable-line
			.then((canvas: { toDataURL: any }) => {
				height = ratio * width;
				const image = canvas.toDataURL('image/jpeg');
				pdf.addImage(image, 0, 0, Number(width), height - 10);
				pdf.addPage();
			});
		await html2canvas(this.myRefNext.current, {height: divHeightNext, width: divWidthNext}) // tslint:disable-line
			.then((canvas: { toDataURL: any }) => {
				height = ratio2 * width;
				const imageNext = canvas.toDataURL('image/jpeg');
				pdf.addImage(imageNext, 0, 0, Number(width), height);
				pdf.save('download.pdf');
			});
		/* jshint ignore:end */
	}
	private readonly uploadNewImage = () => {
		this.props.uploadActionNewImage();
		this.props.filterActionNewImage();
	}
	private readonly getMyRef = (ref: any, refNext: any) => {
		this.myRef = ref;
		this.myRefNext = refNext;
	}
	public render() {
		const {
			uploadActionNewImage,
			idImage,
			pendingImage,
			dateOfBirth,
			patientName,
			imageDate,
			showReport,
			uploadActionShowReport,
			dataImageFilter,
			dataFilter,
			apicalLesions,
			boneLoss,
			restorations,
			imageBase64,
			caries,
		} = this.props;
		if (!pendingImage) {
			return <Redirect to='/upload' />;
		}
		if (showReport) {
			return (
				<div className={styles.app}>
					<HeaderFrame onClick={this.uploadNewImage} />
					<div className={styles.subHeader}>
						<div className={styles.leftContentBlock}>
							<SubHeaderReportFrame
								patientName={patientName}
							/>
							<div onClick={uploadActionShowReport} className={styles.leftContent_analysis}>
								<img src={reportLeft} alt=''/>
								<span>
									<Trans i18nKey='app.report.right_bar_analysis'/>
								</span>
							</div>
							<div className={styles.leftContentContent}>
								<ViewerReportFrame
									getMyRef={this.getMyRef}
									dateOfBirth={dateOfBirth}
									restorations={restorations}
									imageBase64={imageBase64}
									caries={caries}
									boneLoss={boneLoss}
									apicalLesions={apicalLesions}
									dataFilter={dataFilter}
									dataImageFilter={dataImageFilter}
									patientName={patientName}
									imageDate={imageDate}
								/>
							</div>
						</div>
						<div className={styles.rightBar} >
							<div className={styles.rightBarBlock}>
								<span onClick={this.exportPdf} className={styles.rightBarBlock_button}>
									<Trans i18nKey='app.report.right_bar_save'/>
									<img src={reportIcon} alt=''/>
								</span>
								<span className={styles.rightBarBlock_or}>
									<Trans i18nKey='app.report.right_bar_or'/>
								</span>
								<span onClick={this.uploadNewImage} className={styles.rightBarBlock_buttonNew}>
									<Trans i18nKey='app.report.right_bar_new'/>
									<img src={reportNewIcon} alt=''/>
								</span>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className={styles.app}>
				<HeaderFrame onClick={this.uploadNewImage} />
				<div className={styles.subHeader}>
					<div className={styles.leftContent}>
						<SubHeaderFrame
							idImage={idImage}
							dateOfBirth={dateOfBirth}
							patientName={patientName}
							imageDate={imageDate}
						/>
						<ViewerFrame />
					</div>
					<div className={styles.rightBar} >
						<RightBarFrame />
					</div>
				</div>
			</div>
		);
	}
}

import React from 'react';

import style from './toothViewerFrame.module.scss';

import SelectTooth from 'library/common/components/SelectTooth';

interface IRightBarFrame {
	allTooth: any[];
	classModif?: boolean;
	dataFilter: object;
}

const toothTop = [
	{18: 18}, {17: 17}, {16: 16}, {15: 15},
	{14: 14}, {13: 13}, {12: 12}, {11: 11},
];
const toothTopRight = [
	{21: 21}, {22: 22}, {23: 23}, {24: 24},
	{25: 25}, {26: 26}, {27: 27}, {28: 28},
];
const toothBottom = [
	{48: 48}, {47: 47}, {46: 46}, {45: 45},
	{44: 44}, {43: 43}, {42: 42}, {41: 41},
];
const toothBottomRight = [
	{31: 31}, {32: 32}, {33: 33}, {34: 34},
	{35: 35}, {36: 36}, {37: 37}, {38: 38},
];
const ToothViewerFrame: React.FC<IRightBarFrame> = ({allTooth, dataFilter, classModif}: IRightBarFrame) => (
	<div className={`${style.toothViewer} ${classModif && style.classModif}`}>
		<div className={style.toothViewerTop}>
			{toothTop.map(item =>
				(
					<div className={style.toothBlock} key={Object.keys(item)[0]}>
						<div className={style.toothBlock_title}>{Object.keys(item)[0]}</div>
						{allTooth.indexOf(Number(Object.keys(item)[0])) !== -1 ?
							<div className={style.toothBlock_img}>
								{dataFilter[Number(Object.values(item)[0])] ?
								<span>
									{SelectTooth({
										toothNumber: Number(Object.values(item)[0]),
										...dataFilter[Number(Object.values(item)[0])],
									})}
								</span> :
									<span>
										{SelectTooth({
											toothNumber: Number(Object.values(item)[0]),
										})}
									</span>
								}
							</div>
							:
							<div className={style.toothBlock_img} />
						}
					</div>
				))}
			{toothTopRight.map(item =>
				(
					<div className={style.toothBlock} key={Object.keys(item)[0]}>
						<div className={style.toothBlock_title}>{Object.keys(item)[0]}</div>
						{allTooth.indexOf(Number(Object.keys(item)[0])) !== -1 ?
							<div className={style.toothBlock_img}>
								{dataFilter[Number(Object.values(item)[0])] ?
									<span style={{transform: 'scaleX(-1) scaleY(1)'}}>
									{SelectTooth({
										toothNumber: Number(Object.values(item)[0]),
										...dataFilter[Number(Object.values(item)[0])],
									})}
								</span> :
									<span style={{transform: 'scaleX(-1) scaleY(1)'}}>
										{SelectTooth({
											toothNumber: Number(Object.values(item)[0]),
										})}
									</span>
								}
							</div>
							:
							<div className={style.toothBlock_img} />
						}
					</div>
				))}
		</div>
		<div className={style.toothViewerBg} />
		<div className={style.toothViewerBottom}>
			{toothBottom.map(item =>
				(
					<div className={style.toothBlock} key={Object.keys(item)[0]}>
						{allTooth.indexOf(Number(Object.keys(item)[0])) !== -1 ?
							<div className={style.toothBlock_img}>
								{dataFilter[Number(Object.values(item)[0])] ?
									<span>
									{SelectTooth({
										toothNumber: Number(Object.values(item)[0]),
										...dataFilter[Number(Object.values(item)[0])],
									})}
								</span> :
									<span>
										{SelectTooth({
											toothNumber: Number(Object.values(item)[0]),
										})}
									</span>
								}
							</div> :
							<div className={style.toothBlock_img} />
						}
						<div className={style.toothBlock_title}>{Object.keys(item)[0]}</div>
					</div>
				))}
			{toothBottomRight.map(item =>
				(
					<div className={style.toothBlock} key={Object.keys(item)[0]}>
						{allTooth.indexOf(Number(Object.keys(item)[0])) !== -1 ?
							<div className={style.toothBlock_img}>
								{dataFilter[Number(Object.values(item)[0])] ?
									<span style={{transform: 'scaleX(-1) scaleY(1)'}}>
									{SelectTooth({
										toothNumber: Number(Object.values(item)[0]),
										...dataFilter[Number(Object.values(item)[0])],
									})}
								</span> :
									<span style={{transform: 'scaleX(-1) scaleY(1)'}}>
										{SelectTooth({
											toothNumber: Number(Object.values(item)[0]),
										})}
									</span>
								}
							</div> :
							<div className={style.toothBlock_img} />
						}
						<div className={style.toothBlock_title}>{Object.keys(item)[0]}</div>
					</div>
				))}
		</div>
	</div>
);

export default ToothViewerFrame;

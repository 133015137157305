import React from 'react';
import Modal from 'react-modal';
import {Trans} from 'react-i18next';

import modalOpen from 'resources/icons/modal-down.svg';
import closeIcon from 'resources/icons/addNewImgIcon.svg';

import style from './modalAddElementFrame.module.scss';

const customStyles = {
	content : {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '0',
		backgroundColor: 'transparent',
		border: 'none',
	},
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
	},
};

interface IModalAddFrame {
	modalIsOpenAddButton: boolean;
	selectTooth: number;
	dataFilter: object;
	closeModalAddButton: () => void;
	submitModalAddButton: (data: any[]) => void;
}
interface IModalAddFrameState {
	apical: boolean;
	bone: boolean;
	caries: boolean;
	fillings: boolean;
	bridges: boolean;
	crowns: boolean;
	implants: boolean;
	filling: boolean;
	showDetections: boolean;
}
export default class ModalAddElementFrame extends React.Component<IModalAddFrame, IModalAddFrameState> {
	public state: IModalAddFrameState = {
		apical: !!this.props.dataFilter[this.props.selectTooth]['apical'],
		caries: !!this.props.dataFilter[this.props.selectTooth]['caries'],
		bone: !!this.props.dataFilter[this.props.selectTooth]['bone'],
		fillings: !!this.props.dataFilter[this.props.selectTooth]['fillings'],
		crowns: !!this.props.dataFilter[this.props.selectTooth]['crowns'],
		bridges: !!this.props.dataFilter[this.props.selectTooth]['bridges'],
		implants: !!this.props.dataFilter[this.props.selectTooth]['implants'],
		filling: !!this.props.dataFilter[this.props.selectTooth]['filling'],
		showDetections: true,
	};
	private readonly handleCloseModal = () => {
		const {selectTooth, dataFilter} = this.props;
		this.setState({
			apical: !!dataFilter[selectTooth]['apical'],
			caries: !!dataFilter[selectTooth]['caries'],
			bone: !!dataFilter[selectTooth]['bone'],
			fillings: !!dataFilter[selectTooth]['fillings'],
			crowns: !!dataFilter[selectTooth]['crowns'],
			bridges: !!dataFilter[selectTooth]['bridges'],
			implants: !!dataFilter[selectTooth]['implants'],
			filling: !!dataFilter[selectTooth]['filling'],
			showDetections: true,
		});
		this.props.closeModalAddButton();
	}

	private readonly handleSubmit = () => {
		const {apical, caries, bone, fillings, crowns, bridges, implants, filling} = this.state;
		const {selectTooth, dataFilter} = this.props;
		const data = [{}];
		data[0]['toothName'] = selectTooth;
		if (dataFilter[selectTooth]['apical'] || apical) data[0]['apical'] = apical;
		if (dataFilter[selectTooth]['caries'] || caries) data[0]['caries'] = caries;
		if (dataFilter[selectTooth]['bone'] || bone) data[0]['bone'] = bone;
		if (dataFilter[selectTooth]['fillings'] || fillings) data[0]['fillings'] = fillings;
		if (dataFilter[selectTooth]['bridges'] || bridges) data[0]['bridges'] = bridges;
		if (dataFilter[selectTooth]['implants'] || implants) data[0]['implants'] = implants;
		if (dataFilter[selectTooth]['crowns'] || crowns) data[0]['crowns'] = crowns;
		if (dataFilter[selectTooth]['filling'] || filling) data[0]['filling'] = filling;
		this.props.submitModalAddButton(data);
		this.setState({
			apical,
			caries,
			bone,
			fillings,
			crowns,
			bridges,
			implants,
			filling,
			showDetections: true,
		});
	}

	private readonly handleClickApical = () => {
		const {apical} = this.state;
		this.setState({
			apical: !apical,
		});
	}

	private readonly handleClickBone = () => {
		const {bone} = this.state;
		this.setState({
			bone: !bone,
		});
	}

	private readonly handleClickCaries = () => {
		const {caries} = this.state;
		this.setState({
			caries: !caries,
		});
	}

	private readonly handleClickFillings = () => {
		const {fillings} = this.state;
		this.setState({
			fillings: !fillings,
		});
	}

	private readonly handleClickCrowns = () => {
		const {crowns} = this.state;
		this.setState({
			crowns: !crowns,
		});
	}

	private readonly handleClickBridges = () => {
		const {bridges} = this.state;
		this.setState({
			bridges: !bridges,
		});
	}

	private readonly handleClickImplants = () => {
		const {implants} = this.state;
		this.setState({
			implants: !implants,
		});
	}

	private readonly handleClickFilling = () => {
		const {filling} = this.state;
		this.setState({
			filling: !filling,
		});
	}

	public render() {
		const {modalIsOpenAddButton} = this.props;
		const {
			apical, bone, caries, fillings,
			crowns, bridges, implants, filling,
			showDetections,
		} = this.state;
		let styleAnimate = {transition: 'all 300ms ease-in-out', opacity: 1, display: 'flex'};
		if (!showDetections) {
			styleAnimate = {transition: 'all 300ms ease-in-out', opacity: 0, display: 'none'};
		}

		return (
			<div className={style.modalAddFrame}>
				<Modal
					isOpen={modalIsOpenAddButton}
					style={customStyles}
					ariaHideApp={false}
				>
					<div className={style.modalBlock}>
						<div className={style.detectionsAllBlock}>
							<div className={style.detectionsBlock}>
							<span className={style.detectionsBlock_title}>
								<Trans i18nKey='app.rightBar.modalAdd.title_detections'/>
							</span>
								<div className={`${style.detectionsBlock_content} ${showDetections && style.activeContent}`}>
									{showDetections ?
										<div className={style.detectionsBlock_content_blockButtons}>
											{apical &&
												<div className={style.detectionsBlock_content_button}>
													Apical Lesions
													<img
														src={closeIcon}
														onClick={this.handleClickApical}
														alt=''
													/>
												</div>
											}
											{bone &&
												<div className={style.detectionsBlock_content_button}>
													Periodontal Bone Loss
													<img
														src={closeIcon}
														onClick={this.handleClickBone}
														alt=''
													/>
												</div>
											}
											{caries &&
												<div className={style.detectionsBlock_content_button}>
													Caries
													<img
														src={closeIcon}
														onClick={this.handleClickCaries}
														alt=''
													/>
												</div>
											}
											{fillings &&
												<div className={style.detectionsBlock_content_button}>
													Fillings
													<img
														src={closeIcon}
														onClick={this.handleClickFillings}
														alt=''
													/>
												</div>
											}
											{crowns &&
												<div className={style.detectionsBlock_content_button}>
													Crown
													<img
														src={closeIcon}
														onClick={this.handleClickCrowns}
														alt=''
													/>
												</div>
											}
											{bridges &&
												<div className={style.detectionsBlock_content_button}>
													Bridge
													<img
														src={closeIcon}
														onClick={this.handleClickBridges}
														alt=''
													/>
												</div>
											}
											{implants &&
											<div className={style.detectionsBlock_content_button}>
												Implants
												<img
													src={closeIcon}
													onClick={this.handleClickImplants}
													alt=''
												/>
											</div>
											}
											{filling &&
												<div className={style.detectionsBlock_content_button}>
													Root Canal Filling
													<img
														src={closeIcon}
														onClick={this.handleClickFilling}
														alt=''
													/>
												</div>
											}
										</div> :
										<div
											onClick={() => this.setState({showDetections: !showDetections})}
											className={style.detectionsBlock_content_close}
										>
											<Trans i18nKey='app.rightBar.modalAdd.title_input'/>
											<img src={modalOpen} alt=''/>
										</div>
									}
								</div>
							</div>
							<div className={style.selectBlock} style={styleAnimate}>
								<span className={`${apical && style.active}`} onClick={this.handleClickApical}>
									Apical Lesions
								</span>
								<span className={`${bone && style.active}`} onClick={this.handleClickBone}>
									Periodontal Bone Loss
								</span>
								<span className={`${caries && style.active}`} onClick={this.handleClickCaries}>
									Caries
								</span>
								<span className={`${fillings && style.active}`} onClick={this.handleClickFillings}>
									Fillings
								</span>
								<span className={`${crowns && style.active}`} onClick={this.handleClickCrowns}>
									Crown
								</span>
								<span className={`${bridges && style.active}`} onClick={this.handleClickBridges}>
									Bridge
								</span>
								<span className={`${implants && style.active}`} onClick={this.handleClickImplants}>
									Implant
								</span>
								<span className={`${filling && style.active}`} onClick={this.handleClickFilling}>
									Root Canal Filling
								</span>
							</div>
						</div>
						<div className={style.buttonBlock}>
							<span className={style.buttonBlock_close} onClick={this.handleCloseModal}>
								<Trans i18nKey='app.rightBar.modalAdd.button_close'/>
							</span>
							<span className={style.buttonBlock_submit} onClick={this.handleSubmit}>
								<Trans i18nKey='app.rightBar.modalAdd.button_add'/>
							</span>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

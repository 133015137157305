import {call, put, takeLatest} from 'redux-saga/effects';

import {
	uploadActionSuccess, uploadActionWithData,
	uploadActionPreloaderImage, uploadActionError,
	uploadActionGetDataImage, uploadActionGetDataImageSuccess,
} from 'library/common/actions/upload';
import {UploadTypes} from 'library/common/types/uploadTypes';
import {requestImage, requestDataImage} from 'library/services/uploadApi';

// export function* uploadImage(uploadData: ReturnType<typeof uploadActionWithData>) {
export function* uploadImage(uploadData: any) {
	try {
		yield put(uploadActionPreloaderImage());
		const data = yield call(requestImage, uploadData.payload);
		data.imageInfo = uploadData.payload;
		yield put(uploadActionSuccess(data));
	} catch (error) {
		yield put(uploadActionError());
		console.log(error);
	}
}
// export function* getDataImage(idImage: ReturnType<typeof uploadActionGetDataImage>) {
export function* getDataImage(idImage: any) {
	try {
		const data = yield call(requestDataImage, idImage.payload);
		yield put(uploadActionGetDataImageSuccess(data.data));
	} catch (error) {
		console.log(error);
	}
}

export default function* watchUpload() {
	yield takeLatest(UploadTypes.UPLOAD_ACTION_WITH_DATA, uploadImage);
	yield takeLatest(UploadTypes.UPLOAD_ACTION_DATA_IMAGE, getDataImage);
}

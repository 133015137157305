import React from 'react';

import styles from './field.module.scss';

interface IField {
	value: string;
	type: string;
	onChange: (e: any, type: string, isPassword: any) => void;
	clickIcon: (type: string, isPassword: any) => void;
	icon: any;
	showPassword?: any;
}

const Field: React.FC<IField> = ({value, type, onChange, icon, clickIcon, showPassword}: IField) => (
	<div className={styles.field}>
		<input
			className={`${styles.field__input} ${showPassword ? styles.field__input_password : ''}`}
			type={type}
			value={value}
			// placeholder={`${<Trans i18nKey='login.placeholder_email' />}`}
			onChange={e => onChange(e, type, showPassword)}
		/>
		{value &&
		<img
			onClick={() => clickIcon(type, showPassword)}
			className={styles.field__icon}
			src={icon}
			alt=''
		/>
		}
	</div>
);

export default Field;

import React from 'react';
import {Trans} from 'react-i18next';

import style from './subHeaderFrame.module.scss';

interface IHeaderFrame {
	dateOfBirth: string;
	imageDate: string;
	patientName: string;
	idImage: string;
}
const SubHeaderFrame: React.FC<IHeaderFrame> = ({
	idImage,
	dateOfBirth,
	patientName,
	imageDate,
	}: IHeaderFrame) => (
		<div className={style.subHeaderFrame}>
			<div className={style.block}>
				<span className={style.title}>
				 	<Trans i18nKey='app.subHeaderFrame_title1'/>
				</span>
				<span className={style.content}>{idImage}</span>
			</div>
			<div className={style.block}>
				<span className={style.title}>
				 	<Trans i18nKey='app.subHeaderFrame_title2'/>
				</span>
				<span className={style.content}>{patientName}</span>
			</div>
			<div className={style.block}>
				<span className={style.title}>
				 	<Trans i18nKey='app.subHeaderFrame_title3'/>
				 	<span className={style.content}>{dateOfBirth}</span>
				</span>
			</div>
			<div className={style.block}>
				<span className={style.title}>
				 	<Trans i18nKey='app.subHeaderFrame_title4'/>
				</span>
				<span className={style.content}>{imageDate}</span>
			</div>
		</div>
);

export default SubHeaderFrame;

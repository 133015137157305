import React from 'react';
import {Trans} from 'react-i18next';
import {Redirect} from 'react-router-dom';

import Field from '../../library/common/components/Field/Field';
import Checkbox from '../../library/common/components/Checkbox/Checkbox';
import ButtonForm from '../../library/common/components/Button/ButtonForm';

import contentLogo from '../../resources/icons/loginBg.svg';
import loginCheckIcon from '../../resources/icons/loginCheck.svg';
import loginEyeIcon from '../../resources/icons/loginEye.svg';

import styles from './auth.module.scss';

interface IAuth {
	loginActionWithData: (data: object) => void;
	isAuthenticated: boolean;
	loginFailed: boolean;
}

interface IAuthState {
	email: string;
	password: string;
	emptyEmail: boolean;
	showPassword: boolean;
	// copyPassword: string;
	isRemember: boolean;
	showError: boolean;
}

export default class Auth extends React.Component<IAuth, IAuthState> {

	public state: IAuthState = {
		email: '',
		password: '',
		// copyPassword: 'J#zj$CKWK$n96GELfocR6&yM',
		emptyEmail: false,
		showPassword: true,
		showError: false,
		isRemember: true,
	};

	private readonly handleChange = (e: any, type: string, isPassword: any): void => {
		if (typeof isPassword !== 'boolean') {
			this.setState({email: e.target.value, showError: false});
		} else {
			this.setState({password: e.target.value, showError: false});
			// if (this.state.showPassword) {
			// 	const newLetter = e.target.value.slice(-1);
			// 	let newCopyPassword = this.state.copyPassword;
			// 	let newPassword = '';
			// 	if (newLetter !== '*' && newLetter !== '') {
			// 		newCopyPassword += newLetter;
			// 	} else {
			// 		newCopyPassword = newCopyPassword.slice(0, -1);
			// 	}
			// 	// tslint:disable-next-line:prefer-for-of
			// 	for (let i = 0; i < e.target.value.length; i++) {
			// 		newPassword += '*';
			// 	}
			// 	// this.setState({password: newPassword, copyPassword: newCopyPassword});
			// 	this.setState({password: e.target.value, copyPassword: e.target.value});
			// } else {
			// 	this.setState({password: e.target.value, copyPassword: e.target.value});
			// }
		}
		e.persist();
	}

	private readonly handleSubmit = (e: any): void => {
		const {password, email, isRemember} = this.state;
		if (password.length > 0 && email.length > 0) {
			this.props.loginActionWithData({email, password, isRemember});
		}
		this.setState({showError: true});
		e.preventDefault();
	}

	private readonly handleChangeRemember = (e: any): void => {
		this.setState({isRemember: !this.state.isRemember});
		e.preventDefault();
	}

	private readonly clickIcon = (type: string, isPassword: any): void => {
		if (typeof isPassword === 'boolean') {
			this.setState({
				showPassword: !this.state.showPassword,
				password: this.state.password,
			});
			// if (this.state.showPassword) {
			// 	this.setState({
			// 		showPassword: false,
			// 		password: this.state.copyPassword,
			// 	});
			// } else {
			// 	let newPassword = '';
			// 	// tslint:disable-next-line:prefer-for-of
			// 	for (let i = 0; i < this.state.copyPassword.length; i++) {
			// 		newPassword += '*';
			// 	}
			// 	this.setState({
			// 		showPassword: true,
			// 		password: newPassword,
			// 	});
			// }
		}
	}

	public render() {
		const {isAuthenticated, loginFailed} = this.props;
		const {email, password, showPassword, isRemember, showError} = this.state;
		if (isAuthenticated) {
			return <Redirect to='/upload' />;
		}

		return (
			<div className={styles.login}>
				<div className={styles.login__logo}>
					<Trans i18nKey='login.title' />
					<div className={styles.login__logo_sub}><Trans i18nKey='login.sub_title' /></div>
				</div>
				<div className={styles.content}>
					<div className={styles.content__logo}>
						<img className={styles.content__logo_img} src={contentLogo} alt=''/>
					</div>
					<div className={styles.content__form}>
						<div className={styles.content__form_title}><Trans i18nKey='login.form_title' /></div>
						{showError && loginFailed &&
							<div className={styles.login_error}>
								Looks like Email Address or Password is wrong.
							</div>
						}
						<form className={styles.form} onSubmit={this.handleSubmit}>
							<label className={styles.form_label}>
								<Trans i18nKey='login.filed_title_email' />
							</label>
							<Field
								value={email}
								type='text'
								onChange={this.handleChange}
								icon={loginCheckIcon}
								clickIcon={this.clickIcon}
							/>
							<label className={`${styles.form_label} ${styles.form_label_pass}`}>
								<Trans i18nKey='login.filed_title_password' />
							</label>
							<Field
								value={password}
								type={`${showPassword ? 'password' : 'text'}`}
								onChange={this.handleChange}
								icon={loginEyeIcon}
								clickIcon={this.clickIcon}
								showPassword={showPassword}
							/>
							<Checkbox checked={isRemember} handleChangeRemember={this.handleChangeRemember}/>
							<ButtonForm value={<Trans i18nKey='login.form_title' />}/>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

import {all, fork} from 'redux-saga/effects';

import testSaga from 'library/common/sagas/testSaga';
import authenticateSaga from 'library/common/sagas/authenticateSaga';
import uploadSaga from 'library/common/sagas/uploadSaga';
import wsSaga from 'library/common/sagas/wsSaga';

export default function* coreSaga() {
	yield all([
		fork(testSaga),
		fork(authenticateSaga),
		fork(uploadSaga),
		fork(wsSaga),
	]);
}

import {takeLatest, call, put} from 'redux-saga/effects';

import {testActionSuccess, testActionWithData} from '../actions/testActions';
import {requestSomethingId} from '../../services/testApi';
import {TestTypes} from '../types/testTypes';

export function* testActionSagaApi(action: any) {
	try {
		const data = yield call(requestSomethingId, action.payload);
		yield put(testActionSuccess(data));
		// tslint:disable-next-line:no-empty
	} catch (e) {}
}

export default function* watchTest() {
	yield takeLatest(TestTypes.TEST_ACTION_WITH_DATA, testActionSagaApi);
}

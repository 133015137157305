import React from 'react';

import style from './subHeaderReportFrame.module.scss';

interface IHeaderFrame {
	patientName: string;
}
const SubHeaderReportFrame: React.FC<IHeaderFrame> = ({
	patientName,
	}: IHeaderFrame) => (
		<div className={style.subHeaderFrame}>
			<div className={style.block}>
				<span className={style.content}>{patientName}.pdf</span>
			</div>
		</div>
);

export default SubHeaderReportFrame;

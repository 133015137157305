import {ActionType, getType} from 'typesafe-actions';

import * as actions from '../actions/filterImage';

export type filterImageState = Readonly<{
	apicalLesionsStatus: boolean;
	cariesStatus: boolean;
	boneLossStatus: boolean;
	restorationsStatus: boolean;
}>;

const initialState: filterImageState = {
	apicalLesionsStatus: true,
	cariesStatus: true,
	boneLossStatus: true,
	restorationsStatus: true,
};

export type filterImageActions = ActionType<typeof actions>;

export default (state = initialState, action: filterImageActions): filterImageState => {
	switch (action.type) {
		case getType(actions.filterActionApicalLesions):

			return {
				...state,
				apicalLesionsStatus: !state.apicalLesionsStatus,
			};
		case getType(actions.filterActionBoneLoss):

			return {
				...state,
				boneLossStatus: !state.boneLossStatus,
			};
		case getType(actions.filterActionCaries):

			return {
				...state,
				cariesStatus: !state.cariesStatus,
			};
		case getType(actions.filterActionRestorations):

			return {
				...state,
				restorationsStatus: !state.restorationsStatus,
			};
		case getType(actions.filterActionNewImage):

			return {
				...initialState,
			};
		case getType(actions.filterActionAll):
			if (action.payload.isChecked) {
				return {
					...state,
					restorationsStatus: action.payload.showRestorations,
					cariesStatus: action.payload.showCaries,
					boneLossStatus: action.payload.showBone,
					apicalLesionsStatus: action.payload.showApical,
				};
			}

			return {
				...state,
				restorationsStatus: action.payload.isChecked,
				cariesStatus: action.payload.isChecked,
				boneLossStatus: action.payload.isChecked,
				apicalLesionsStatus: action.payload.isChecked,
			};
		default:
			return state;
	}
};

import React from 'react';
import {Trans} from 'react-i18next';

import uploadImgSmall from 'resources/icons/uploudImgSmall.svg';
// import headerSearch from 'resources/icons/headerSearch.svg';

import style from './headerFrame.module.scss';
interface IHeaderFrame {
	onClick: () => void;
}
const HeaderFrame: React.FC<IHeaderFrame> = ({onClick}: IHeaderFrame) => (
	<div className={style.headerFrame}>
		<span onClick={onClick} className={style.headerFrame_button}>
			<img src={uploadImgSmall} alt=''/>
			<Trans i18nKey='app.headerFrame_button' />
		</span>
		{/*<div className={style.headerFrame_search}>*/}
		{/*	<input*/}
		{/*		type='text'*/}
		{/*		className={style.headerFrame_input}*/}
		{/*		placeholder='Search Detection or Tooth'*/}
		{/*		// placeholder={String(<Trans i18nKey='app.headerFrame_input' />)}*/}
		{/*	/>*/}
		{/*	<img src={headerSearch} alt=''/>*/}
		{/*</div>*/}
	</div>
);

export default HeaderFrame;

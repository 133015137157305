import React from 'react';
import {Trans} from 'react-i18next';
import InputRange from 'react-input-range';

import navigateIcon from 'resources/icons/navigate.svg';
import imageIcon from 'resources/icons/imageIcon.svg';
import rotateIcon from 'resources/icons/rotateIcon.svg';
import flipIcon from 'resources/icons/flipIcon.svg';
import undoIcon from 'resources/icons/undo.svg';
import redoIcon from 'resources/icons/redoIcon.svg';
import resetIcon from 'resources/icons/resetIcon.svg';
import zoomInImg from 'resources/icons/zoomInImg.svg';
import zoomOutImg from 'resources/icons/zoomOutImg.svg';
import flipIconTop from 'resources/icons/flipIconTop.svg';
import flipIconLeft from 'resources/icons/flipIconLeft.svg';

import style from './ViewerFrame.module.scss';

interface IViewer {
	imageBase64: string;
	apicalLesionsStatus: boolean;
	boneLossStatus: boolean;
	cariesStatus: boolean;
	restorationsStatus: boolean;
	apicalLesions: any[];
	boneLoss: any[];
	caries: any[];
	restorations: any[];
}

interface IViewerState {
	imageBase64: string;
	rotate: number;
	width: number;
	height: number;
	drag: boolean;
	scale: number;
	scaleXLeft: string;
	scaleYTop: string;
	left: number;
	top: number;
	isMouseDown?: boolean;
	mouseX?: number;
	mouseY?: number;
	contrast: number;
	saturate: number;
	brightness: number;
	showZoom: boolean;
	showImage: boolean;
	showRotate: boolean;
	showFlip: boolean;
}

export default class ViewerImage extends React.Component<IViewer, IViewerState> {
	private myContainer: any;
	private readonly myRef: any;
	private readonly hash: any;
	private activeIndexHash: number;

	constructor(props: IViewer) {
		super(props);
		this.myRef = React.createRef();
		this.activeIndexHash = 1;
		this.hash = [];
	}

	public state: IViewerState = {
		imageBase64: this.props.imageBase64,
		rotate: 0,
		width: 0,
		height: 0,
		drag: false,
		scale: 1,
		scaleXLeft: '',
		scaleYTop: '',
		left: 0,
		top: 0,
		isMouseDown: false,
		mouseX: 0,
		mouseY: 0,
		contrast: 100,
		saturate: 100,
		brightness: 100,
		showZoom: false,
		showImage: false,
		showRotate: false,
		showFlip: false,
	};

	public componentDidMount(): void {
		this.myContainer = this.myRef.current;
		if (this.myContainer) {
			this.bindEvent(this.state.drag);
			this.myContainer.addEventListener('wheel', this.handleMouseScroll, false);
			this.setState(
				{height: this.myContainer.clientHeight, width: Number(this.myContainer.clientWidth) + 2},
				() => this.updateHash());
		}
	}

	public componentDidUpdate(prevProps: IViewer, prevState: IViewerState) {
		if (prevState.drag !== this.state.drag) {
			this.bindEvent(this.state.drag);
		}
	}
	public componentWillUnmount() {
		const funcName = 'removeEventListener';
		this.myContainer.removeEventListener('wheel', this.handleMouseScroll, false);
		this.myContainer.removeEventListener('mousemove', this.handleMouseMove, false);
		document[funcName]('click', this.handleMouseUp, false);
		document[funcName]('mousemove', this.handleMouseMove, false);
		window[funcName]('resize', this.handleResize, false);
	}

	public bindEvent = (remove?: boolean) => {
		let funcName = 'addEventListener';
		if (remove) {
			funcName = 'removeEventListener';
			if (this.myContainer) this.myContainer.addEventListener('mousemove', this.handleMouseMove, false);
		} else {
			if (this.myContainer) this.myContainer.removeEventListener('mousemove', this.handleMouseMove, false);
		}

		document['addEventListener']('click', this.handleMouseUp, false);
		document[funcName]('mousemove', this.handleMouseMove, false);
		window[funcName]('resize', this.handleResize, false);
	}

	private readonly handleMouseScroll = (e: any) => {
		e.preventDefault();
		let direct: 0 | 1 | -1 = 0;
		const value = e.deltaY;
		if (value === 0) {
			direct = 0;
		} else {
			direct = value > 0 ? -1 : 1;
		}
		if (direct !== 0) {
			let x = e.clientX;
			let y = e.clientY;
			if (this.myContainer) {
				const containerRect = this.myContainer.getBoundingClientRect();
				x -= containerRect.left;
				y -= containerRect.top;
			}
			const zoomSpeed = 0.05;
			this.handleZoomScroll(x, y, direct, zoomSpeed);
		}
	}

	private readonly handleZoomScroll = (targetX: number, targetY: number, direct: number, scale: number) => {
		const imgCenterXY = {
			x: this.state.left + this.state.width / 2,
			y: this.state.top + this.state.height / 2,
		};
		const diffX = targetX - imgCenterXY.x;
		const diffY = targetY - imgCenterXY.y;
		let top: number;
		let left: number;
		let scaleXY: number;
		const directXY = this.state.scale > 0 ? 1 : -1;
		scaleXY = this.state.scale + scale * direct * directXY;
		if (Math.abs(scaleXY) < 0.1 || scaleXY < 1) {
			return;
		}
		top = this.state.top + -direct * diffY / this.state.scale * scale * directXY;
		left = this.state.left + -direct * diffX / this.state.scale * scale * directXY;
		this.setState({
			scale: scaleXY,
			top,
			left,
		}, () => this.updateHash());
	}

	private readonly handleMouseUp = () => {
		this.setState({
			isMouseDown: false,
		});
	}

	private readonly handleMouseMove = (e: any) => {
		if (this.state.isMouseDown) {
			if (this.state.drag) {
				const diffX = e.clientX - Number(this.state.mouseX);
				const diffY = e.clientY - Number(this.state.mouseY);
				this.setState({
					mouseX: e.clientX,
					mouseY: e.clientY,
					top: this.state.top + diffY,
					left: this.state.left + diffX,
				});
			} else {
				const newWidth = this.state.width / 300;
				const newWidthY = this.state.width / 400;
				const diffX = e.clientX / newWidth;
				const diffY = e.clientY / newWidthY;
				if (diffY > 300 || diffY < 100) {
					if (diffX > 300 || diffX < 100) {
						return this.setState({
							brightness: diffX,
						}, () => this.updateHash());
					}

					return;
				}
				if (diffX > 300 || diffX < 100) {
					return this.setState({
						contrast: diffY,
					}, () => this.updateHash());
				}
				this.setState({
						brightness: diffX,
						contrast: diffY,
				}, () => this.updateHash());
			}
		}
	}

	private readonly handleResize = () => {
		const left = (this.myContainer.clientWidth - this.state.width) / 2;
		const top = (this.myContainer.clientHeight - this.state.height) / 2;
		this.setState({
			left,
			top,
		});
	}

	private readonly handleMouseDown = (e: any) => {
		if (e.button !== 0) {
			return;
		}
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			isMouseDown: true,
			mouseX: e.nativeEvent.clientX,
			mouseY: e.nativeEvent.clientY,
		});
	}

	private readonly handleRotate = (isRight: boolean = false) => {
		this.setState({
			rotate: this.state.rotate + 90 * (isRight ? 1 : -1),
		}, () => this.updateHash());
	}

	private readonly handleDrag = () => {
		this.setState({
			drag: !this.state.drag,
		}, () => this.updateHash(true));
	}

	private readonly handleReset = () => {
		this.setState({
			rotate: 0,
			drag: false,
			scale: 1,
			scaleXLeft: '',
			scaleYTop: '',
			left: 0,
			top: 0,
			isMouseDown: false,
			mouseX: 0,
			mouseY: 0,
			contrast: 100,
			saturate: 100,
			brightness: 100,
			showZoom: false,
			showImage: false,
			showRotate: false,
			showFlip: false,
		}, () => this.updateHash(true));
	}

	private readonly handleZoom = (isPlus: boolean) => {
		let newScale = isPlus ? this.state.scale + 0.5 : this.state.scale - 0.5;
		if (newScale < 0.5) {
			newScale = 0.5;
		}
		if (newScale < 1) {
			return;
		}
		this.setState({
			scale: newScale,
		}, () => this.updateHash());
	}

	private readonly handleFlipTop = () => {
		this.setState({
			scaleYTop: this.state.scaleYTop ? '' : '-',
		}, () => this.updateHash());
	}

	private readonly handleFlipLeft = () => {
		this.setState({
			scaleXLeft: this.state.scaleXLeft ? '' : '-',
		}, () => this.updateHash());
	}

	private readonly handleUndo = () => {
		if (this.hash[this.activeIndexHash - 1]) {
			this.setState({...this.hash[this.activeIndexHash - 1]});
			this.activeIndexHash = this.activeIndexHash - 1;
		}
	}

	private readonly handleRedo = () => {
		if (this.hash[this.activeIndexHash + 1]) {
			this.activeIndexHash = this.activeIndexHash + 1;
			this.setState({...this.hash[this.activeIndexHash]});
		}
	}

	private readonly updateHash = (isNavBar?: boolean) => {
		if (this.activeIndexHash + 1 !== this.hash.length) {
			this.hash.slice(0, this.activeIndexHash);
		}
		if (isNavBar) {
			this.setState({
				showZoom: false,
				showImage: false,
				showRotate: false,
				showFlip: false,
			});
		}
		this.hash.push(this.state);
		this.activeIndexHash = this.hash.length - 1;
	}

	private readonly handleContrast = (value: any) => {
		this.setState({contrast: value});
	}

	private readonly handleSaturate = (value: any) => {
		this.setState({saturate: value});
	}

	private readonly handleBrightness = (value: any) => {
		this.setState({brightness: value});
	}

	private readonly showZoom = (e: any) => {
		this.setState({
			showZoom: !this.state.showZoom,
			showImage: false,
			showRotate: false,
			showFlip: false,
		});
	}

	private readonly showImage = () => {
		this.setState({
			showZoom: false,
			showImage: !this.state.showImage,
			showRotate: false,
			showFlip: false,
		});
	}

	private readonly showRotate = () => {
		this.setState({
			showZoom: false,
			showImage: false,
			showRotate: !this.state.showRotate,
			showFlip: false,
		});
	}

	private readonly showFlip = () => {
		this.setState({
			showZoom: false,
			showImage: false,
			showRotate: false,
			showFlip: !this.state.showFlip,
		});
	}

	public render() {
		const {
			rotate,
			scale, scaleXLeft, scaleYTop,
			contrast, saturate, brightness,
			showZoom, showImage, showRotate,
			showFlip, imageBase64,
		} = this.state;
		const {
			apicalLesionsStatus, boneLossStatus, cariesStatus,
			restorationsStatus, apicalLesions, boneLoss,
			caries, restorations,
		} = this.props;
		const imgStyle: React.CSSProperties = {
			filter: `contrast(${contrast}%) saturate(${saturate}%) brightness(${brightness}%)`,
			transform: `
			translateX(${this.state.left}px) translateY(${this.state.top}px)
			rotate(${rotate}deg) scaleX(${scaleXLeft}${scale}) scaleY(${scaleYTop}${scale})`,
		};
		const imgStyleSmall: React.CSSProperties = {
			filter: `contrast(${contrast}%) saturate(${saturate}%) brightness(${brightness}%)`,
		};
		const InputRangeClassNames = {
			inputRange: style.inputRangeName,
			labelContainer: style.labelContainer,
			maxLabel: style.maxLabel,
			minLabel: style.minLabel,
			sliderContainer: style.sliderContainer,
			slider: style.slider,
			track: style.track,
			activeTrack: style.activeTrack,
			disabledInputRange: style.disabledInputRange,
			valueLabel: style.valueLabel,
		};
		const InputRangeClassNamesContrast = {...InputRangeClassNames};
		InputRangeClassNamesContrast.slider = style.sliderContrast;
		const InputRangeClassNamesSaturate = {...InputRangeClassNames};
		InputRangeClassNamesSaturate.slider = style.sliderSaturate;

		return (
			<div className={style.viewerImage}>
				<div className={style.navBar}>
					<span className={style.navBar_button} onClick={this.handleDrag}>
						<span className={style.navBar_button_title}><Trans i18nKey='app.viewer.navigate'/></span>
						<img className={style.navBar_button_img} src={navigateIcon} alt=''/>
					</span>
					<span className={style.navBar_button}>
						<span className={style.navBar_button_title} onClick={this.showZoom}><Trans i18nKey='app.viewer.zoom'/></span>
						<img
							className={`${style.navBar_button_img}
							 ${style.navBar_button_zoom}`}
							onClick={this.showZoom}
							src={zoomInImg}
							alt=''
						/>
						<span onClick={this.showZoom} className={style.navBar_button_triangle} />
						{showZoom &&
						<div className={`${style.navBar_button_content} ${style.zoomBar}`}>
							<span onClick={() => this.handleZoom(true)} className={style.zoomImg}>
								<img className={style.zoomBar_in} src={zoomInImg} alt=''/>
							</span>
							<span onClick={() => this.handleZoom(false)} className={`${style.zoomImg} ${style.zoomImg_modif}`}>
								<img className={style.zoomBar_out} src={zoomOutImg} alt=''/>
							</span>
							<div className={style.zoomBar_count}>
								{Math.round(scale)}<span className={style.zoomBar_count_modif} >:</span>{Math.round(scale)}
							</div>
						</div>
						}
					</span>
					<span className={style.navBar_button}>
						<span className={style.navBar_button_title} onClick={this.showImage}><Trans i18nKey='app.viewer.image'/></span>
						<img
							className={`${style.navBar_button_img}
							 ${style.navBar_button_image}`}
							onClick={this.showImage}
							src={imageIcon}
							alt=''
						/>
						{showImage &&
						<div className={style.navBar_button_content}>
							<div className={style.range}>
								<div className={style.range_blockImage}>
									<img className={style.range_image} style={imgStyleSmall} src={imageBase64} alt=''/>
								</div>
								<span className={style.inputRange}>
									<Trans i18nKey='app.viewer.exposure'/>
									<InputRange
										maxValue={300}
										minValue={100}
										step={10}
										classNames={InputRangeClassNames}
										value={brightness}
										onChange={this.handleBrightness}
									/>
								</span>
								<span className={style.inputRange}>
									<Trans i18nKey='app.viewer.contrast'/>
									<InputRange
										maxValue={300}
										minValue={100}
										step={10}
										classNames={InputRangeClassNamesContrast}
										value={contrast}
										onChange={this.handleContrast}
									/>
								</span>
								<span className={style.inputRange}>
									<Trans i18nKey='app.viewer.saturate'/>
									<InputRange
										maxValue={300}
										minValue={100}
										step={10}
										classNames={InputRangeClassNamesSaturate}
										value={saturate}
										onChange={this.handleSaturate}
									/>
								</span>
							</div>
						</div>
						}
					</span>
					<span className={style.navBar_button}>
						<span className={style.navBar_button_title} onClick={this.showRotate}><Trans i18nKey='app.viewer.rotate'/></span>
						<img
							className={`${style.navBar_button_img}
							 ${style.navBar_button_rotate}`}
							onClick={this.showRotate}
							src={rotateIcon}
							alt=''
						/>
						<span onClick={this.showRotate} className={style.navBar_button_triangle} />
						{showRotate &&
						<div className={`${style.navBar_button_content} ${style.zoomBar}`}>
							<span onClick={() => this.handleRotate()} className={style.zoomImg}>
								<img className={style.zoomBar_in} src={rotateIcon} alt=''/>
							</span>
							<span onClick={() => this.handleRotate(true)} className={`${style.zoomImg} ${style.zoomImg_modif}`}>
								<img className={style.zoomBar_out} src={rotateIcon} alt=''/>
							</span>
						</div>
						}
					</span>
					<span className={style.navBar_button}>
						<span className={style.navBar_button_title} onClick={this.showFlip}><Trans i18nKey='app.viewer.flip'/></span>
						<img
							className={`${style.navBar_button_img}
								 ${style.navBar_button_flip}`}
							onClick={this.showFlip}
							src={flipIcon}
							alt=''
						/>
						<span onClick={this.showFlip} className={style.navBar_button_triangle} />
						{showFlip &&
						<div className={`${style.navBar_button_content} ${style.zoomBar}`}>
							<span onClick={this.handleFlipTop} className={style.zoomImg}>
								<img className={style.zoomBar_in} src={flipIconTop} alt=''/>
							</span>
							<span onClick={this.handleFlipLeft} className={`${style.zoomImg} ${style.zoomImg_modif}`}>
								<img className={style.zoomBar_out} src={flipIconLeft} alt=''/>
							</span>
						</div>
						}
					</span>
					<span className={style.navBar_button} onClick={this.handleUndo}>
						<span className={style.navBar_button_title}><Trans i18nKey='app.viewer.undo'/></span>
						<img
							className={`${style.navBar_button_img}
								 ${style.navBar_button_undo}`}
							src={undoIcon}
							alt=''
						/>
					</span>
					<span className={style.navBar_button} onClick={this.handleRedo}>
						<span className={style.navBar_button_title}><Trans i18nKey='app.viewer.redo'/></span>
						<img
							className={`${style.navBar_button_img}
								 ${style.navBar_button_redo}`}
							src={redoIcon}
							alt=''
						/>
					</span>
					<span className={style.navBar_button} onClick={this.handleReset}>
						<span className={style.navBar_button_title}><Trans i18nKey='app.viewer.reset'/></span>
						<img
							className={`${style.navBar_button_img}
								 ${style.navBar_button_reset}`}
							src={resetIcon}
							alt=''
						/>
					</span>
				</div>
				<div className={style.containerViewerImg}>
					<div onMouseDown={this.handleMouseDown} ref={this.myRef} className={style.containerViewer}>
						<img
							className={`${style.container_image}
						 ${ this.state.drag && style.drag}`}
							style={imgStyle}
							src={imageBase64}
							alt=''
						/>
						{restorationsStatus &&
						restorations.map((item: any) =>
							(
								<img
									key={item.id}
									className={`${style.container_image}
							 ${ this.state.drag && style.drag} ${style.container_image_modif}`}
									style={imgStyle}
									src={`data:image/png;base64,${item.mask}`}
									alt=''
								/>
							))
						}
						{apicalLesionsStatus &&
						apicalLesions.map((item: any) =>
							(
								<img
									key={item.id}
									className={`${style.container_image}
							 ${ this.state.drag && style.drag} ${style.container_image_modif}`}
									style={imgStyle}
									src={`data:image/png;base64,${item.mask}`}
									alt=''
								/>
							))
						}
						{boneLossStatus &&
						boneLoss.map((item: any) =>
							(
								<img
									key={item.id}
									className={`${style.container_image}
							 ${ this.state.drag && style.drag} ${style.container_image_modif}`}
									style={imgStyle}
									src={`data:image/png;base64,${item.mask}`}
									alt=''
								/>
							))
						}
						{cariesStatus &&
						caries.map((item: any) =>
							(
								<img
									key={item.id}
									className={`${style.container_image}
							 ${ this.state.drag && style.drag} ${style.container_image_modif}`}
									style={imgStyle}
									src={`data:image/png;base64,${item.mask}`}
									alt=''
								/>
							))
						}
					</div>
					<div className={style.zoomImages}><Trans i18nKey='app.viewer.zoomImg'/> {Math.round(scale * 100)}%</div>
				</div>
			</div>
		);
	}
}

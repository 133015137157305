import React from 'react';
import {Trans} from 'react-i18next';

import ToothViewerFrame from '../RightBarFrame/Frames/ToothViewerFrame';

import iconLogo from 'resources/icons/logoReport.svg';
import tooth1 from 'resources/icons/tooth1.svg';
import tooth2 from 'resources/icons/tooth2.svg';
import tooth3 from 'resources/icons/tooth3.svg';
import tooth4 from 'resources/icons/tooth4.svg';
import tooth5 from 'resources/icons/tooth5.svg';
import tooth6 from 'resources/icons/tooth6.svg';

import style from './viewerReportFrame.module.scss';

const toothMap = [
	{'Apical Lesions': tooth1},
	{Caries: tooth2},
	{Filling: tooth3},
	{Crown: tooth4},
	{Bridge: tooth5},
	{'Root Canal Filling': tooth6},
];
const toothTop = [18, 17, 16, 15, 14, 13, 12, 11];
const toothTopRight = [28, 27, 26, 25, 24, 23, 22, 21];
const toothBottom = [48, 47, 46, 45, 44, 43, 42, 41];
const toothBottomRight = [38, 37, 36, 35, 34, 33, 32, 31];

interface IViewerReportFrame {
	patientName: string;
	dateOfBirth: string;
	imageDate: string;
	dataImageFilter: any[];
	dataFilter: object;
	apicalLesions: any[];
	boneLoss: any[];
	caries: any[];
	restorations: any[];
	imageBase64: string;
	getMyRef: (ref: any, refNext: any) => void;
}
interface IViewerReportFrameState {
	dentist: string;
}
export default class ViewerReportFrame extends React.Component<IViewerReportFrame, IViewerReportFrameState> {
	private readonly myRefPdf = React.createRef<HTMLDivElement>();
	private readonly myRefPdfOne = React.createRef<HTMLDivElement>();
	public state: IViewerReportFrameState = {
		dentist: 'Mr. Falk Schwendicke',
	};
	public componentDidMount(): void {
		this.props.getMyRef(this.myRefPdfOne, this.myRefPdf);
	}

	public render() {
		const {
			 apicalLesions, boneLoss, dataImageFilter, dataFilter,
			caries, restorations, imageBase64, patientName,
			dateOfBirth, imageDate,
		} = this.props;
		let toothTopContentState = false;
		let toothTopRightContentState = false;
		let toothBottomContentState = false;
		let toothBottomRightState = false;
		const toothTopContent = toothTop.map(item => {
			if (dataFilter[item]) {
				if (
					dataFilter[item]['apical'] || dataFilter[item]['fillings'] ||  dataFilter[item]['caries']
					|| dataFilter[item]['crowns'] || dataFilter[item]['bridges'] || dataFilter[item]['comment']
				) {
					toothTopContentState = true;

					return (
						<div className={style.toothBlockPage} key={item}>
							<div className={style.toothBlockPageItem}>
								<span className={style.toothBlockPageItemTitle}>{item}</span>
								<div className={style.toothBlockPageItemContent}>
									{dataFilter[item]['apical'] &&
									<span>Apical Lesions</span>
									}
									{dataFilter[item]['caries'] &&
									<span>Caries</span>
									}
									{dataFilter[item]['fillings'] &&
									<span>Filling</span>
									}
									{dataFilter[item]['crowns'] &&
									<span>Crown</span>
									}
									{dataFilter[item]['bridges'] &&
									<span>Bridge</span>
									}
								</div>
							</div>
							<div className={style.commentBlockContent}>
								{dataFilter[item]['comment'] &&
								<span className={style.commentBlock}>
								<span>Note :-</span>
								<span>{dataFilter[item]['comment']}</span>
							</span>
								}
							</div>
						</div>
					);
				}
			}
		});
		const toothTopRightContent = toothTopRight.map(item => {
			if (dataFilter[item]) {
				if (
					dataFilter[item]['apical'] || dataFilter[item]['fillings'] ||  dataFilter[item]['caries']
					|| dataFilter[item]['crowns'] || dataFilter[item]['bridges'] || dataFilter[item]['comment']
				) {
					toothTopRightContentState = true;

					return (
						<div className={style.toothBlockPage} key={item}>
							<div className={style.toothBlockPageItem}>
								<span className={style.toothBlockPageItemTitle}>{item}</span>
								<div className={style.toothBlockPageItemContent}>
									{dataFilter[item]['apical'] &&
									<span>Apical Lesions</span>
									}
									{dataFilter[item]['caries'] &&
									<span>Caries</span>
									}
									{dataFilter[item]['fillings'] &&
									<span>Filling</span>
									}
									{dataFilter[item]['crowns'] &&
									<span>Crown</span>
									}
									{dataFilter[item]['bridges'] &&
									<span>Bridge</span>
									}
								</div>
							</div>
							<div className={style.commentBlockContent}>
								{dataFilter[item]['comment'] &&
								<span className={style.commentBlock}>
								<span>Note :-</span>
								<span>{dataFilter[item]['comment']}</span>
							</span>
								}
							</div>
						</div>
					);
				}
			}
		});
		const toothBottomContent = toothBottom.map(item => {
			if (dataFilter[item]) {
				if (
					dataFilter[item]['apical'] || dataFilter[item]['fillings'] ||  dataFilter[item]['caries']
					|| dataFilter[item]['crowns'] || dataFilter[item]['bridges'] || dataFilter[item]['comment']
				) {
					toothBottomContentState = true;

					return (
						<div className={style.toothBlockPage} key={item}>
							<div className={style.toothBlockPageItem}>
								<span className={style.toothBlockPageItemTitle}>{item}</span>
								<div className={style.toothBlockPageItemContent}>
									{dataFilter[item]['apical'] &&
									<span>Apical Lesions</span>
									}
									{dataFilter[item]['caries'] &&
									<span>Caries</span>
									}
									{dataFilter[item]['fillings'] &&
									<span>Filling</span>
									}
									{dataFilter[item]['crowns'] &&
									<span>Crown</span>
									}
									{dataFilter[item]['bridges'] &&
									<span>Bridge</span>
									}
								</div>
							</div>
							<div className={style.commentBlockContent}>
								{dataFilter[item]['comment'] &&
								<span className={style.commentBlock}>
								<span>Note :-</span>
								<span>{dataFilter[item]['comment']}</span>
							</span>
								}
							</div>
						</div>
					);
				}
			}
		});
		const toothBottomRightContent = toothBottomRight.map(item => {
			if (dataFilter[item]) {
				if (
					dataFilter[item]['apical'] || dataFilter[item]['fillings'] ||  dataFilter[item]['caries']
					|| dataFilter[item]['crowns'] || dataFilter[item]['bridges'] || dataFilter[item]['comment']
				) {
					toothBottomRightState = true;

					return (
						<div className={style.toothBlockPage} key={item}>
							<div className={style.toothBlockPageItem}>
								<span className={style.toothBlockPageItemTitle}>{item}</span>
								<div className={style.toothBlockPageItemContent}>
									{dataFilter[item]['apical'] &&
									<span>Apical Lesions</span>
									}
									{dataFilter[item]['caries'] &&
									<span>Caries</span>
									}
									{dataFilter[item]['fillings'] &&
									<span>Filling</span>
									}
									{dataFilter[item]['crowns'] &&
									<span>Crown</span>
									}
									{dataFilter[item]['bridges'] &&
									<span>Bridge</span>
									}
								</div>
							</div>
							<div className={style.commentBlockContent}>
								{dataFilter[item]['comment'] &&
								<span className={style.commentBlock}>
								<span>Note :-</span>
								<span>{dataFilter[item]['comment']}</span>
							</span>
								}
							</div>
						</div>
					);
				}
			}
		});

		return (
			<div>
				<div ref={this.myRefPdfOne} id='elemClass' className={style.viewerReportFrame}>
					<div className={style.viewerReportFrame_header}>
						<img src={iconLogo} alt=''/>
						<span><Trans i18nKey='app.report.leftBar_header'/></span>
						<span className={style.title}><Trans i18nKey='app.report.leftBar_header_blue'/></span>
						<span><Trans i18nKey='app.report.leftBar_header_sub'/></span>
					</div>
					<div className={style.infoBLock}>
						<div className={style.leftBlock}>
							<div className={style.content}>
							<span className={style.content_title}>
								<Trans i18nKey='app.report.infoBLock.dentist'/>
							</span>
								<span className={style.content_titleSub}>:</span>
								<span className={style.content_info}>{this.state.dentist}</span>
							</div>
							<div className={style.content}>
							<span className={style.content_title}>
								<Trans i18nKey='app.report.infoBLock.name'/>
							</span>
								<span className={style.content_titleSub}>:</span>
								<span className={style.content_info}>{patientName}</span>
							</div>
							<div className={style.content}>
							<span className={style.content_title}>
								<Trans i18nKey='app.report.infoBLock.DOB'/>
							</span>
								<span className={style.content_titleSub}>:</span>
								<span className={style.content_info}>{dateOfBirth}</span>
							</div>
						</div>
						<div className={`${style.leftBlock} ${style.modifBlock}`}>
							<div className={style.content}>
							<span className={style.content_title}>
								<Trans i18nKey='app.report.infoBLock.date'/>
							</span>
								<span className={style.content_titleSub}>:</span>
								<span className={style.content_info}>{imageDate}</span>
							</div>
						</div>
					</div>
					<div className={style.containerViewer}>
						<img
							className={`${style.container_image}`}
							src={imageBase64}
							alt=''
						/>
						{restorations.map((item: any) =>
							(
								<img
									key={item.id}
									className={`${style.container_image} ${style.container_image_modif}`}
									src={`data:image/png;base64,${item.mask}`}
									alt=''
								/>
							))
						}
						{apicalLesions.map((item: any) =>
							(
								<img
									key={item.id}
									className={`${style.container_image} ${style.container_image_modif}`}
									src={`data:image/png;base64,${item.mask}`}
									alt=''
								/>
							))
						}
						{boneLoss.map((item: any) =>
							(
								<img
									key={item.id}
									className={`${style.container_image} ${style.container_image_modif}`}
									src={`data:image/png;base64,${item.mask}`}
									alt=''
								/>
							))
						}
						{caries.map((item: any) =>
							(
								<img
									key={item.id}
									className={`${style.container_image} ${style.container_image_modif}`}
									src={`data:image/png;base64,${item.mask}`}
									alt=''
								/>
							))
						}
					</div>
					<div className={style.subHeader}>
						<Trans i18nKey='app.report.infoBLock.subHeader'/>
					</div>
					<ToothViewerFrame classModif={true} allTooth={dataImageFilter} dataFilter={dataFilter}/>
					<div className={style.subHeader}>
						<Trans i18nKey='app.report.infoBLock.subHeader_tooth'/>
					</div>
					<div className={style.toothBlock}>
						{toothMap.map(item =>
							(
								<div className={style.toothBlockContent} key={String(Object.values(item))}>
									<img src={String(Object.values(item))} alt=''/>
									<span>{Object.keys(item)}</span>
								</div>
							))}
					</div>
				</div>
				<div ref={this.myRefPdf} id='elemClass2' className={style.viewerReportFrameBottom}>
					<div className={style.viewerReportFrame_header}>
						<img src={iconLogo} alt=''/>
						<span><Trans i18nKey='app.report.leftBar_header'/></span>
						<span className={style.title}><Trans i18nKey='app.report.leftBar_header_blue'/></span>
						<span><Trans i18nKey='app.report.leftBar_header_sub'/></span>
					</div>
					<div className={style.viewerReportFrame_block}>
						<span className={style.titleBLock}>Detections List</span>
						{toothTopContentState &&
							<div>
								<span className={style.subTitle}>Tooth No (18-11)</span>
								{toothTopContent}
							</div>
						}
						{toothTopRightContentState &&
							<div style={{marginTop: '10px'}}>
								<span className={style.subTitle}>Tooth No (28-21)</span>
								{toothTopRightContent}
							</div>
						}
						{toothBottomContentState &&
						<div style={{marginTop: '10px'}}>
							<span className={style.subTitle}>Tooth No (48-41)</span>
							{toothBottomContent}
						</div>
						}
						{toothBottomRightState &&
						<div style={{marginTop: '10px'}}>
							<span className={style.subTitle}>Tooth No (38-31)</span>
							{toothBottomRightContent}
						</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

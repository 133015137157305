import {take, takeLatest, call, put} from 'redux-saga/effects';
import {eventChannel} from 'redux-saga';
import {UploadTypes} from '../types/uploadTypes';

function initWebsocket(uploadData: any) {
	return eventChannel(emitter => {
		const ws = new WebSocket('wss://biteport.dentalxr.ai/result-socket/');
		ws.onopen = () => {
			ws.send(uploadData);
		};
		ws.onerror = error => {
			console.dir(error);
		};
		ws.onmessage = e => {
			let msg = null;
			try {
				msg = JSON.parse(e.data);
			} catch (e) {
				console.error(`Error parsing : ${e.data}`);
			}
			if (msg) {
				if (msg.status === 'done') {
					return emitter({type: UploadTypes.UPLOAD_ACTION_WS_SUCCESS});
				}
			}
		};

		return () => {
			console.log('Socket off');
		};
	});
}
export function* uploadImage(uploadData: any) {
	const channel = yield call(initWebsocket, uploadData.payload);
	// tslint:disable-next-line:no-constant-condition
	while (true) {
		const action = yield take(channel);
		yield put(action);
	}
}
export default function* wsSagas() {
	yield takeLatest(UploadTypes.UPLOAD_ACTION_WS_OPEN, uploadImage);
}

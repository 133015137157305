import React from 'react';

import styles from './buttonForm.module.scss';

interface IButtonForm {
	value: any;
}

const ButtonForm: React.FC<IButtonForm> = ({value}: IButtonForm) => (
	<button className={styles.buttonForm} type='submit' >{value}</button>
);

export default ButtonForm;

import React from 'react';

import ModalAddElementFrame from '../ModalAddElementFrame';

import arrowRestoration from 'resources/icons/arrowRestoration.svg';
import addIcon from 'resources/icons/addIconHover.svg';
import commentIcon from 'resources/icons/commentIcon.svg';

import style from './toothElementFrame.module.scss';

interface IRightBarFrame {
	toothElement: any;
	uploadActionAddNewTooth: (data: any) => void;
	dataFilter: object;
}
interface IRightBarFrameState {
	showRestoration: boolean;
	showComment: boolean;
	modalIsOpenAddButton: boolean;
	comment: string;
}
export default class ToothElementFrame extends React.Component<IRightBarFrame, IRightBarFrameState> {
	public state: IRightBarFrameState = {
		showRestoration: false,
		modalIsOpenAddButton: false,
		showComment: false,
		comment: this.props.dataFilter[this.props.toothElement]['comment'],
	};
	private readonly handleShowRestoration = () => {
		this.setState({
			showRestoration: !this.state.showRestoration,
		});
	}
	private readonly handleShowComment = () => {
		const {comment} = this.state;
		this.setState({
			showComment: !this.state.showComment,
			comment,
		});
	}
	private readonly handleChangeComment = (e: any) => {
		this.setState({
			comment: e.target.value,
		});
	}
	private readonly handleDeleteComment = () => {
		this.props.uploadActionAddNewTooth([{
			toothName: this.props.toothElement,
			comment: '',
		}]);
		this.setState({
			comment: '',
			showComment: !this.state.showComment,
		});
	}
	private readonly handleSaveComment = () => {
		this.props.uploadActionAddNewTooth([{
			toothName: this.props.toothElement,
			comment: this.state.comment,
		}]);
		this.setState({
			showComment: !this.state.showComment,
		});
	}
	private readonly toggleIsOpenAddButton = () => {
		this.setState({
			modalIsOpenAddButton: !this.state.modalIsOpenAddButton,
		});
	}
	private readonly submitModalAddButton = (data: any[]) => {
		this.props.uploadActionAddNewTooth(data);
		this.setState({
			modalIsOpenAddButton: !this.state.modalIsOpenAddButton,
		});
	}
	public render() {
		const {toothElement, dataFilter} = this.props;
		const {showRestoration, showComment, comment} = this.state;
		let viewRestoration  = false;
		if (dataFilter[toothElement]['crowns']) viewRestoration = true;
		if (dataFilter[toothElement]['implants']) viewRestoration = true;
		if (dataFilter[toothElement]['fillings']) viewRestoration = true;
		if (dataFilter[toothElement]['filling']) viewRestoration = true;
		if (dataFilter[toothElement]['bridges']) viewRestoration = true;

		return (
			<div className={style.content}>
				<ModalAddElementFrame
					modalIsOpenAddButton={this.state.modalIsOpenAddButton}
					closeModalAddButton={this.toggleIsOpenAddButton}
					submitModalAddButton={this.submitModalAddButton}
					selectTooth={toothElement}
					dataFilter={dataFilter}
				/>
				<div className={style.editButtonBlock}>
					<img onClick={this.toggleIsOpenAddButton}  className={style.editButtonBlock_add} src={addIcon} alt=''/>
					<img onClick={this.handleShowComment} className={style.editButtonBlock_comment} src={commentIcon} alt=''/>
				</div>
				<div className={style.contentNumber}>{toothElement}</div>
				<div className={style.contentButtons}>
					{dataFilter[toothElement]['apical'] &&
					<span className={`${style.contentButton} ${style.apical}`}>Apical Lesions</span>
					}
					{dataFilter[toothElement]['bone'] &&
					<span className={`${style.contentButton} ${style.filling}`}>Periodontal Bone Loss</span>
					}
					{dataFilter[toothElement]['caries'] &&
					<span className={`${style.contentButton} ${style.caries}`}>Caries</span>
					}
					{viewRestoration &&
						<div className={style.restorationBlock}>
							<span
								onClick={this.handleShowRestoration}
								className={`${style.contentButton} ${style.contentButton_res}`}
							>
								Restorations
								<img
									className={`${showRestoration && style.restorationImg_active} ${style.restorationImg}`}
									src={arrowRestoration}
									alt=''
								/>
							</span>
							{showRestoration &&
							<div className={style.restorationBlock_content}>
								{dataFilter[toothElement]['crowns'] &&
								<span className={`${style.contentButton} ${style.crown}`}>Crown</span>
								}
								{dataFilter[toothElement]['implants'] &&
								<span className={`${style.contentButton} ${style.implant}`}>Implant</span>
								}
								{dataFilter[toothElement]['fillings'] &&
								<span className={`${style.contentButton} ${style.filling}`}>Fillings</span>
								}
								{dataFilter[toothElement]['filling'] &&
								<span className={`${style.contentButton} ${style.filling}`}>Root-canal Filling</span>
								}
								{dataFilter[toothElement]['bridges'] &&
								<span className={`${style.contentButton} ${style.bridges}`}>Bridge</span>
								}
							</div>}
						</div>
					}
					{showComment ?
						<div className={style.commentBlock}>
							<div>
								<textarea
									className={style.commentBlock_textarea}
									placeholder={'Your Text Here'}
									value={comment}
									onChange={this.handleChangeComment}
								/>
							</div>
							<div className={style.commentBlock_buttons}>
								<span onClick={this.handleDeleteComment} className={style.commentBlock_button_del}>Delete</span>
								<span onClick={this.handleSaveComment} className={style.commentBlock_buttons_save}>Save</span>
							</div>
						</div> :
						<div onClick={this.handleShowComment} className={style.handleShowComment}>
							{comment &&
							<span className={`${style.contentButton} ${style.bridges}`}>{comment}</span>
							}
						</div>
					}
				</div>
			</div>
		);
	}
}

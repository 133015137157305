import {connect} from 'react-redux';

import {RootState} from 'core/store/configureStore';

import {uploadActionWithData} from 'library/common/actions/upload';
import {getPendingImage, getPreloaderImage} from 'library/common/selectors/upload';

import Upload from './Upload';

const mapStateToProps = (store: RootState) => ({
	pending: getPendingImage(store),
	preloader: getPreloaderImage(store),
});

export default connect(mapStateToProps, {
	uploadActionWithData,
})(Upload);

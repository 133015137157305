import {ActionType, getType} from 'typesafe-actions';

import localStorage from 'library/utilities/localStorage';
import {setBearerToken} from 'library/utilities/token';
import * as actions from 'library/common/actions/user';

export type UserState = Readonly<{
	isAuthenticated: boolean;
	email: string;
	isRemember: boolean;
	loginFailed: boolean;
}>;

const token = localStorage.getItem('access_token');
const initialState: UserState = {
	isAuthenticated: !!token,
	email: localStorage.getItem('email') || '',
	isRemember: true,
	loginFailed: false,
};
if (token)setBearerToken(token);

export type UserActions = ActionType<typeof actions>;

export default (state = initialState, action: UserActions): UserState => {
	switch (action.type) {
		case getType(actions.logOutAction):
			localStorage.removeItem('access_token');
			localStorage.removeItem('email');

			return {
				...state,
				isAuthenticated: false,
				loginFailed: false,
			};

		case getType(actions.loginAction):
			return {
				...state,
				isAuthenticated: true,
			};
		case getType(actions.loginActionSuccess):
			return {
				...state,
				isRemember: action.payload.isRemember,
				email: action.payload.email,
				loginFailed: false,
			};
		case getType(actions.loginActionError):
			return {
				...state,
				loginFailed: true,
			};
		case getType(actions.loginActionWithData):
			return {
				...state,
			};

		default:
			return state;
	}
};

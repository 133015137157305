import {call, put, takeLatest} from 'redux-saga/effects';

import localStorage from 'library/utilities/localStorage';
import {loginActionSuccess, loginAction, loginActionError} from 'library/common/actions/user';
import {uploadActionNewImage} from 'library/common/actions/upload';
import {UserTypes} from 'library/common/types/userTypes';
import {setBearerToken} from 'library/utilities/token';
import {requestLogin} from 'library/services/userApi';

// export function* loginUser(userData: ReturnType<typeof loginActionWithData>) {
export function* loginUser(userData: any) {
	try {
		const authResponse = yield call(requestLogin, userData.payload);
		if (userData.payload.isRemember) {
			localStorage.setItem('access_token', authResponse.data.token);
			localStorage.setItem('email', userData.payload.email);
		}
		setBearerToken(authResponse.data.token);
		yield put(uploadActionNewImage());
		yield put(loginActionSuccess(userData.payload));
		yield put(loginAction());
	} catch (error) {
		yield put(loginActionError());
	}
}

export default function* watchLogin() {
	yield takeLatest(UserTypes.LOGIN_ACTION_WITH_DATA, loginUser);
}

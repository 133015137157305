import {connect} from 'react-redux';

import {RootState} from 'core/store/configureStore';

import {
	getDataFilter,
	getIdImages,
	getDataImageFilter,
	getStatusFilter,
	getShowButton,
} from 'library/common/selectors/upload';
import {uploadActionGetDataImage, uploadActionOpenWs,
	uploadActionAddNewTooth, uploadActionShowReport,
} from 'library/common/actions/upload';
import {
	filterActionRestorations, filterActionCaries,
	filterActionBoneLoss, filterActionApicalLesions,
	filterActionAll,
} from 'library/common/actions/filterImage';

import RightBarFrame from './RightBarFrame';
import {
	getApicalLesionsStatus,
	getBoneLossStatus,
	getCariesStatus, getRestorationsStatus,
} from 'library/common/selectors/filterImage';

const mapStateToProps = (store: RootState) => ({
	dataFilter: getDataFilter(store),
	idImage: getIdImages(store),
	dataImageFilter: getDataImageFilter(store),
	statusFilter: getStatusFilter(store),
	showButton: getShowButton(store),
	showApical: getApicalLesionsStatus(store),
	showBone: getBoneLossStatus(store),
	showCaries: getCariesStatus(store),
	showRestorations: getRestorationsStatus(store),
});

export default connect(mapStateToProps, {
	uploadActionGetDataImage, filterActionRestorations,
	filterActionCaries, filterActionBoneLoss,
	filterActionApicalLesions, filterActionAll,
	uploadActionAddNewTooth, uploadActionShowReport,
	uploadActionOpenWs,
})(RightBarFrame);

import React from 'react';
import {Trans} from 'react-i18next';

import styles from './checkbox.module.scss';

interface ICheckbox {
	checked: boolean;
	handleChangeRemember: (e: any) => void;
}

const Checkbox: React.FC<ICheckbox> = ({checked, handleChangeRemember}: ICheckbox) => (
	<div className={styles.checkbox}>
		<div className={styles.checkbox_content}>
			<input
				className={styles.checkbox_input}
				type='checkbox'
				readOnly={true}
				checked={checked}
			/>
			<span onClick={handleChangeRemember} className={styles.checkbox_checkmark} />
			<Trans i18nKey='login.checkbox_remember_me' />
		</div>
	</div>
);

export default Checkbox;

import {createStandardAction} from 'typesafe-actions';

import {UploadTypes} from '../types/uploadTypes';

export const uploadActionNewImage = createStandardAction(UploadTypes.UPLOAD_ACTION_NEW_IMAGE)();
export const uploadActionPreloaderImage = createStandardAction(UploadTypes.UPLOAD_ACTION_PRELOADER_IMAGE)();
export const uploadActionError = createStandardAction(UploadTypes.UPLOAD_ACTION_ERROR)();
export const uploadActionSuccess =
		createStandardAction(UploadTypes.UPLOAD_ACTION_SUCCESS)<any>();
export const uploadActionGetDataImage =
		createStandardAction(UploadTypes.UPLOAD_ACTION_DATA_IMAGE)<any>();
export const uploadActionGetDataImageSuccess =
	createStandardAction(UploadTypes.UPLOAD_ACTION_DATA_IMAGE_SUCCESS)<any>()

export const uploadActionWithData =
		createStandardAction(UploadTypes.UPLOAD_ACTION_WITH_DATA)<any>();
export const uploadActionAddNewTooth =
		createStandardAction(UploadTypes.UPLOAD_ACTION_ADD_NEW_TOOTH)<any>();
export const uploadActionShowReport = createStandardAction(UploadTypes.UPLOAD_ACTION_SHOW_REPORT)();
export const uploadActionOpenWs =
	createStandardAction(UploadTypes.UPLOAD_ACTION_WS_OPEN)<any>();
export const uploadActionOpenWsSuccess =
	createStandardAction(UploadTypes.UPLOAD_ACTION_WS_SUCCESS)();

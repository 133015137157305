import {connect} from 'react-redux';

import {RootState} from 'core/store/configureStore';

import {
	getImageBase64,
	getDataApical,
	getDataBone,
	getDataCaries,
	getDataRestorations,
} from 'library/common/selectors/upload';
import {
	getApicalLesionsStatus, getBoneLossStatus,
	getCariesStatus, getRestorationsStatus,
} from 'library/common/selectors/filterImage';

import ViewerFrame from './ViewerFrame';

const mapStateToProps = (store: RootState) => ({
	imageBase64: getImageBase64(store),
	apicalLesions : getDataApical(store),
	boneLoss : getDataBone(store),
	caries : getDataCaries(store),
	restorations : getDataRestorations(store),
	apicalLesionsStatus: getApicalLesionsStatus(store),
	boneLossStatus: getBoneLossStatus(store),
	cariesStatus: getCariesStatus(store),
	restorationsStatus: getRestorationsStatus(store),
});

export default connect(mapStateToProps, null)(ViewerFrame);

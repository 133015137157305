import {createStandardAction} from 'typesafe-actions';

import {filteImageTypes} from '../types/filterImageTypes';

export const filterActionApicalLesions = createStandardAction(filteImageTypes.FILTER_ACTION_APICAL)();
export const filterActionBoneLoss = createStandardAction(filteImageTypes.FILTER_ACTION_BONE)();
export const filterActionCaries = createStandardAction(filteImageTypes.FILTER_ACTION_CARIES)();
export const filterActionRestorations = createStandardAction(filteImageTypes.filter_ACTION_RESTORATIONS)();
export const filterActionAll = createStandardAction(filteImageTypes.filter_ACTION_All)<any>();
export const filterActionNewImage = createStandardAction(filteImageTypes.filter_ACTION_NEW_IMAGE)();

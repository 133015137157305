import React from 'react';
import {Trans} from 'react-i18next';

import notificationIcon from 'resources/icons/bell.svg';
import userIcon from 'resources/icons/userLogo.svg';
import menuIcon from 'resources/icons/arrov.svg';

import styles from './headerFrame.module.scss';

export interface IProps {
	logOutAction: () => void;
	email: string;
}
export interface IState {
	openMenu: boolean;
}
export default class Header extends React.Component<IProps, IState> {
	public state: IState = {
		openMenu: false,
	};

	public render() {
		const {logOutAction, email} = this.props;
		const {openMenu} = this.state;

		return (
			<div className={styles.header}>
				<div className={styles.header__logo}>
					<Trans i18nKey='login.title' />
				</div>
				<div className={styles.header__user}>
					<div className={styles.header__notification}>
						<img src={notificationIcon} alt=''/>
					</div>
					<div className={styles.header__user}>
						<img src={userIcon} alt=''/>
					</div>
					<div className={styles.header__info}>
						{email}
						<span className={styles.header__prof}>Dentist</span>
					</div>
					<div className={styles.header__menu}>
						<div onClick={() => this.setState({openMenu: !openMenu})} className={styles.header__menu_icon}>
							<img src={menuIcon} alt=''/>
						</div>
						{openMenu &&
						<div onClick={logOutAction} className={styles.header__menu_logOut}>
							<Trans i18nKey='login.button_singOut' />
						</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

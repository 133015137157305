import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import App from 'pages/App';
import Auth from 'pages/Auth';
import Upload from 'pages/Upload';
import HomeContainer from 'pages/Home/containers/HomeContainer';

import ProtectedLayout from 'library/common/components/Layouts/ProtectedLayout/';

const Routes = () => (
	<Switch>
		<Redirect exact from='/' to='/upload' />
		<Route exact path='/login' component={Auth} />
		<Route exact path='/' component={App} />
		<Route exact path='/image' component={HomeContainer} />
		<Route
			path='/'
			render={() => (
				<ProtectedLayout>
					<Switch>
						<Route exact path='/' component={HomeContainer} />
						<Route path='/dashboard' component={App} />
						<Route path='/upload' component={Upload} />
					</Switch>
				</ProtectedLayout>
			)}
		/>
	</Switch>
);

export default Routes;

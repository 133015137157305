import React from 'react';
import {Redirect} from 'react-router-dom';

import HeaderFrame from './Frames/HeaderFrame';

export interface IProps {
	children: any;
	isAuthenticated: boolean;
	logOutAction: () => void;
	email: string;
}

export default class ProtectedLayout extends React.Component<IProps> {

	public render() {
		const {children, isAuthenticated, email} = this.props;
		if (!isAuthenticated) {
			return <Redirect to='/login' />;
		}

		return (
			<React.Fragment>
				<HeaderFrame email={email} logOutAction={this.props.logOutAction} />
				{children}
			</React.Fragment>
		);
	}
}

import React from 'react';

import ToothViewerFrame from './Frames/ToothViewerFrame';
import ModalAddFrame from './Frames/ModalAddFrame';
import ToothElementFrame from './Frames/ToothElementFrame';

import addIcon from 'resources/icons/addNewImgIcon.svg';

import style from './rightBarFrame.module.scss';
import {Trans} from 'react-i18next';

interface IRightBarFrame {
	statusFilter: boolean;
	dataImageFilter: any[];
	dataFilter: object;
	idImage: string;
	showButton: boolean;
	showApical: boolean;
	showBone: boolean;
	showCaries: boolean;
	showRestorations: boolean;
	uploadActionGetDataImage: (id: string) => void;
	uploadActionAddNewTooth: (data: any[]) => void;
	uploadActionOpenWs: (data: any) => void;
	filterActionAll: (state: object) => void;
	filterActionRestorations: () => void;
	filterActionCaries: () => void;
	filterActionBoneLoss: () => void;
	filterActionApicalLesions: () => void;
	uploadActionShowReport: () => void;
}
interface IRightBarFrameState {
	filterData: any;
	showApical: boolean;
	showBone: boolean;
	showCaries: boolean;
	showRestorations: boolean;
	isChecked: boolean;
	modalIsOpenAddButton: boolean;
}
export default class RightBarFrame extends React.Component<IRightBarFrame, IRightBarFrameState> {
	public state: IRightBarFrameState = {
		showApical: this.props.showApical,
		showBone: this.props.showBone,
		showCaries: this.props.showCaries,
		showRestorations: this.props.showRestorations,
		modalIsOpenAddButton: false,
		isChecked: true,
		filterData: {},
	};
	public componentDidMount(): void {
		const {showRestorations, showCaries, showBone, showApical} = this.props;
		if (!showRestorations && !showCaries && !showBone && !showApical) {
			this.setState({isChecked: false});
		}
		this.props.uploadActionOpenWs(this.props.idImage);
	}

	private readonly handleApical = () => {
		const {
			isChecked, showApical, showBone,
			showCaries, showRestorations,
		} = this.state;

		if (!isChecked) {
			this.props.filterActionAll({
				isChecked: true,
				showRestorations: false,
				showCaries: false,
				showBone: false,
				showApical: true,
			});
			this.setState({
				isChecked: true,
				showRestorations: false,
				showCaries: false,
				showBone: false,
				showApical: true,
			});
		} else {
			if (showApical && !showBone && !showCaries && !showRestorations) {
				this.setState({
					showApical: !showApical,
					isChecked: false,
				});
			} else {
				this.setState({
					showApical: !showApical,
				});
			}
			this.props.filterActionApicalLesions();
		}
	}

	private readonly handleBone = () => {
		const {
			isChecked, showApical, showBone,
			showCaries, showRestorations,
		} = this.state;

		if (!isChecked) {
			this.props.filterActionAll({
				isChecked: true,
				showRestorations: false,
				showCaries: false,
				showBone: true,
				showApical: false,
			});
			this.setState({
				isChecked: true,
				showRestorations: false,
				showCaries: false,
				showBone: true,
				showApical: false,
			});
		} else {
			if (!showApical && showBone && !showCaries && !showRestorations) {
				this.setState({
					showApical: !showApical,
					isChecked: false,
				});
			} else {
				this.setState({
					showBone: !showBone,
				});
			}
			this.props.filterActionBoneLoss();
		}
	}

	private readonly handleCaries = () => {
		const {
			isChecked, showApical, showBone,
			showCaries, showRestorations,
		} = this.state;

		if (!isChecked) {
			this.props.filterActionAll({
				isChecked: true,
				showRestorations: false,
				showCaries: true,
				showBone: false,
				showApical: false,
			});
			this.setState({
				isChecked: true,
				showRestorations: false,
				showCaries: true,
				showBone: false,
				showApical: false,
			});
		} else {
			if (!showApical && !showBone && showCaries && !showRestorations) {
				this.setState({
					showApical: !showApical,
					isChecked: false,
				});
			} else {
				this.setState({
					showCaries: !showCaries,
				});
			}
			this.props.filterActionCaries();
		}
	}

	private readonly handleRestorations = () => {
		const {
			isChecked, showApical, showBone,
			showCaries, showRestorations,
		} = this.state;

		if (!isChecked) {
			this.props.filterActionAll({
				isChecked: true,
				showRestorations: true,
				showCaries: false,
				showBone: false,
				showApical: false,
			});
			this.setState({
				isChecked: true,
				showRestorations: true,
				showCaries: false,
				showBone: false,
				showApical: false,
			});
		} else {
			if (!showApical && !showBone && !showCaries && showRestorations) {
				this.setState({
					showApical: !showApical,
					isChecked: false,
				});
			} else {
				this.setState({
					showRestorations: !showRestorations,
				});
			}
			this.props.filterActionRestorations();
		}
	}

	private readonly handleChangeFilterAll = () => {
		const {showApical, showBone, showCaries, showRestorations, isChecked} = this.state;
		this.props.filterActionAll({
			isChecked: !isChecked,
			showRestorations,
			showCaries,
			showBone,
			showApical,
		});
		this.setState({
			isChecked: !isChecked,
			showRestorations,
			showCaries,
			showBone,
			showApical,
		});
	}
	private readonly handleChangeResetAll = () => {
		this.props.filterActionAll({
			isChecked: true,
			showRestorations: true,
			showCaries: true,
			showBone: true,
			showApical: true,
		});
		this.setState({
			isChecked: true,
			showRestorations: true,
			showCaries: true,
			showBone: true,
			showApical: true,
		});
	}

	private readonly handleShowView = () => {
		this.props.uploadActionGetDataImage(this.props.idImage);
	}

	private readonly toggleIsOpenAddButton = () => {
		this.setState({
			modalIsOpenAddButton: !this.state.modalIsOpenAddButton,
		});
	}
	private readonly submitModalAddButton = (data: any[]) => {
		this.props.uploadActionAddNewTooth(data);
		this.setState({
			modalIsOpenAddButton: !this.state.modalIsOpenAddButton,
		});
	}

	public render() {
		const {statusFilter, dataFilter, uploadActionAddNewTooth, dataImageFilter, showButton} = this.props;
		const {showApical, showBone, showCaries, showRestorations, isChecked} = this.state;

		return (
			<div className={style.rightBarFrame}>
				{statusFilter &&
					<div className={style.preloaderRight}>
						<span><Trans i18nKey='app.rightBar.title_spinner'/></span>
						<div className={style.lds_spinner}>
							<div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/>
						</div>
					</div>
				}
				{(!statusFilter && showButton) &&
					<div className={style.preloaderButton}>
						<span className={style.preloaderButton_title}>
							<Trans i18nKey='app.rightBar.title_button_preloader'/>
						</span>
						<span onClick={this.handleShowView} className={style.preloaderButton_button}>
							<Trans i18nKey='app.rightBar.title_button_text'/>
						</span>
					</div>
				}
				<ModalAddFrame
					modalIsOpenAddButton={this.state.modalIsOpenAddButton}
					closeModalAddButton={this.toggleIsOpenAddButton}
					submitModalAddButton={this.submitModalAddButton}
				/>
				<ToothViewerFrame allTooth={dataImageFilter} dataFilter={dataFilter}/>
				<div className={style.titleBLock}>
					<div className={style.title}>
						<Trans i18nKey='app.rightBar.titleBLock'/>
						<span className={style.testClass} onClick={this.toggleIsOpenAddButton}><img src={addIcon} alt=''/></span>
					</div>
					<div className={style.filterAllBLock}>
						<Trans i18nKey='app.rightBar.titleBLock_switch'/>
						<div className={style.reset_Button}>
							{(!showApical || !showBone || !showCaries || !showRestorations) &&
							<span className={style.reset_Button} onClick={this.handleChangeResetAll}>
							<Trans i18nKey='app.rightBar.titleBLock_reset'/>
						</span>
							}
						</div>
						<label className={style.switch}>
							<input type='checkbox' checked={isChecked} onChange={this.handleChangeFilterAll} />
							<div className={style.slider} >
								{isChecked && <span><Trans i18nKey='app.rightBar.titleBLock_switch_on'/></span>}
								{!isChecked && <span><Trans i18nKey='app.rightBar.titleBLock_switch_off'/></span>}
							</div>
						</label>
					</div>
				</div>
				<div className={style.filterBlock}>
					<div className={style.filterBlockButton}>
						<button
							onClick={this.handleApical}
							className={`${style.filterBlock_button} ${isChecked ? showApical && style.active : ''}`}
						>
							<Trans i18nKey='app.rightBar.title_apical'/>
						</button>
						<button
							onClick={this.handleBone}
							className={`${style.filterBlock_button} ${isChecked ? showBone && style.active : ''}`}
						>
							<Trans i18nKey='app.rightBar.title_bone'/>
						</button>
						<button
							onClick={this.handleCaries}
							className={`${style.filterBlock_button} ${isChecked ? showCaries && style.active : ''}`}
						>
							<Trans i18nKey='app.rightBar.title_caries'/>
						</button>
						<button
							onClick={this.handleRestorations}
							className={`${style.filterBlock_button}
							 ${isChecked ? showRestorations && style.active : isChecked}`}
						>
							<Trans i18nKey='app.rightBar.title_restorations'/>
						</button>
					</div>
				</div>
				<div className={style.contentBlock}>
					{Object.keys(dataFilter).length > 0 &&
						<div>
							{Object.keys(dataFilter).map(item =>
								(
									<ToothElementFrame
										key={item}
										uploadActionAddNewTooth={uploadActionAddNewTooth}
										dataFilter={dataFilter}
										toothElement={item}
									/>
								))}
						</div>
					}
				</div>
				<div className={style.reportBlock}>
					<span onClick={this.props.uploadActionShowReport}><Trans i18nKey='app.rightBar.report_text_button'/></span>
				</div>
			</div>
		);
	}
}
